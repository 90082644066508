
import React, { Component } from 'react';
import { Modal, Button } from "react-bootstrap";

class EditEduApp extends Component {
    constructor(props) {
        super(props);
        this.state = {
            client:'',
            provider:'',
            type:'',
            pCourses:[],
            pIntakes:[],
            pPackages:[],
            packageBundle:[],
            subPackages:[],
            courseid:'',
            intakeid:'',
            packageid:'',
        }
        this.handleChange = this.handleChange.bind(this);
        this.handleOptionChange = this.handleOptionChange.bind(this);
    }
     // call on cancel
     handleModal = () => {
         this.props.parentCallback(false); 
    } 
     // onchange
     handleChange(event) {
        this.setState({
            [event.target.name]: event.target.value
        });
    }
  
     handleOptionChange(e) {
        this.setState({
            [e.target.name]: e.target.options[e.target.selectedIndex].text
        });
    }
    
  render(){ 
    return(
        <>
         <Modal size="lg" className="p-modal-content" show={this.props.show} onHide={() => this.props.parentCallback('close')}>  
            <Modal.Header closeButton><div className="text-center col-11 size-18 font-monst">Admission Application</div></Modal.Header>  
            <Modal.Body>
            <form action=''>
            <div className='row px-3 mb-3 justify-content-center'>

            <div className='col-lg-8'>
             <label className="acc-label2 size-18 mb-0">Client</label>
             <select value={this.props.data['clientid'] || ""} className="form-select app-form acc-form" onChange={this.handleChange} aria-label="form-select-lg example" name="client" disabled>
             <option>Please select</option>
             {this.props.allClients.map(value => 
             <option value={value.id}>{value.name}</option>
             )}
             </select>
             </div>

             {(this.state.client || this.props.data['clientid']) &&
             <div className='col-lg-8 mt-3'>
             <label className="acc-label2 size-18 mb-0">Provider</label>
             <select value={this.props.providerid || ""} className="form-select app-form acc-form" name="provider" onChange={(e) => this.props.parentCallback(false, e.target.value, 'provider')}>
             <option >Please Select</option>
             {[].concat(this.props.allProviders).sort((a, b) => a.name > b.name ? 1 : -1).map((value, index) => 
             <option key={index} value={value.id}>{value.name}</option>
             )}
             </select>
             </div>}

             {this.props.data['providerid'] &&
             <div className='col-lg-8 mt-3'>
             <label className="acc-label2 size-18 mb-0">Select Course Type</label>
             <select value={this.props.coursetype || ""} className="form-select app-form acc-form" onChange={(e) => this.props.parentCallback(false, e.target.value, 'type')} aria-label="form-select-lg example" name="client">
             <option value="">Please select</option>
             <option value='single'>Single</option>
             <option value='package'>Package</option>
             </select>
             </div>}

             {this.props.coursetype === 'single' &&
             <div className="col-lg-8 mt-3">
             <label className="acc-label2 size-18 mb-0">Courses</label>
             <select value={this.props.courseid || ""} className="form-select app-form acc-form" name="courseid" aria-label="form-select-lg" onChange={(e) => this.props.parentCallback(false, e.target.value, 'course')}>
             <option>Select Course</option>
             {[].concat(this.props.allCourses).sort((a, b) => a.name > b.name ? 1 : -1).map(value => 
             <option key={value.id} value={value.id}>{value.name}</option> )}
             </select>
             </div>}

             {this.props.courseid &&
             <div className="col-lg-8 mt-3">
                <label className="acc-label2 size-18 mb-0">Course Intake</label>
                <select value={this.props.intakeid} className="form-select app-form acc-form" name="intakeid" onChange={(e) => this.props.parentCallback(false, e.target.value, 'intake')} aria-label="form-select-lg">
                <option>Please Select</option>
                {[].concat(this.props.intakes).sort((a, b) => a.title > b.title ? 1 : -1).map(value => 
                <option key={value.id} value={value.intake_id}>{value.title}</option> )}
                </select>
              </div>}

             {this.props.coursetype === 'package' &&
             <div className="col-lg-8 mt-3">
             <label className="acc-label2 size-18 mb-0">Packages</label>
             <select className="form-select app-form" value={this.props.packageid}
             onChange={(e) => this.props.parentCallback(false, e.target.value, 'package')} aria-label="form-select-lg">
             <option value="">Please Select</option>
             {[].concat(this.props.allPackages).sort((a, b) => a.name > b.name ? 1 : -1).map(value => 
             <option key={value.id} value={value.id}>{value.name}</option> )}
             </select>
             </div>}

             {this.props.packageBundle[0] && 
             <div className="col-lg-8 mt-3">
             <div className="form-group">
             <ul className="pl-4 border mt-lg-2 list-group">
                {this.props.packageBundle.map((value, key) => 
                    <li key={key} className="acc-label2 p-1">{value.name}</li>
                )}
                {this.props.subPackages &&
                <>
                {this.props.subPackages.map(sub => {
                    return(
                    <>
                    {sub.map(s => ( <li className="acc-label2 p-1" key={s.id}> {s.name} </li>))}
                    </>
                )})}
                </>}
             </ul>
          </div>
             </div>}

             <div className="col-lg-8 mt-2">
             <label className="acc-label2 mb-1">Notes</label>
             <textarea value={this.props.notes} className="textarea border pl-2 app-form acc-form" name="note" onChange={(e) => this.props.parentCallback(false, e.target.value, 'notes')}
             placeholder="Please enter..." rows="4" cols="60"></textarea>
             </div>


             </div>
            </form>
            <Modal.Footer>
            {(this.props.providerid && (this.props.courseid || this.props.packageid)) &&
            <Button className="btn btn-success col-12" onClick={() => this.props.parentCallback('edit', this.props.data['appid'])}>Update</Button>}  
            <Button className="btn btn-danger col-2" onClick={()=>this.props.parentCallback('close')}>Close</Button>  
            </Modal.Footer>
            </Modal.Body>
            </Modal>
        </>
    )
  }
}
export default EditEduApp;