
import React ,{useState,useEffect}from "react"
import moment from 'moment';
import { get_ApiManager, post_ApiManager, view_ApiManager } from "../ApiManager"
import { Link } from "react-router-dom";
import { Sorting } from "../GenericComponents/SortTables";
import CreateEduApp from "./createEduApp";
import Paginations from "../GenericComponents/Pagination";
import { FaEdit, FaEye } from 'react-icons/fa';
import ExportOptions from "../GenericComponents/ExportOptions";
import EditEduApp from "./editEduApp";
import swal from 'sweetalert';

const AllEducationApps = () => {
    const [perPage, setPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [lastPage, setLastPage] = useState('');
    const [totalItem, setTotalItem] = useState('');
    const [allEduApps, setAllEduApps] = useState([]);
    const [allClients, setAllClients] = useState([]);
    const [allCourses, setAllCourses] = useState([]);
    const [allProviders, setAllProviders] = useState([]);
    const [selectedEdu, setSelectedEdu] = useState([]);
    const [eduIndex, setEduIndex] = useState('');
    const [perItem, setPerItem] = useState('');
    const [order, setOrder] = useState("ASC");
    const [showModel, setShowModel] = useState(false);
    const [subPackages, setSubPackages] = useState([])
    const [packageBundle, setPackageBundle] = useState([])
    const [editAppData ,setEditAppData] = useState([]);
    const [allPackages ,setAllPackages] = useState([]);
    const [intakes, setIntakes] = useState([]);
    const [showEditModel, setShowEditModel] = useState(false);
    const [coursetype, setCourseType] = useState('');
    const [courseid, setCourseId] = useState('');
    const [intakeid, setIntakeId] = useState('');
    const [providerid, setProviderId] = useState('');
    const [packageid, setPackageId] = useState('');
    const [notes, setNotes] = useState('');
    const [headers, setHeaders] = useState([ 
        {title: '#' , dataKey: "", align: "text-center", key:1, checked: true, canChange: false},
        {title: 'Client' , dataKey: "clientname", align: "text-center", key:2, checked: true, canChange: false},
        {title: 'Course/Package', dataKey: "coursename" , align:"text-center", key:3, checked: true, canChange: false},
        {title: 'Provider', dataKey: "providername", align:"text-center", key:4, checked: true, canChange: false},
        {title: 'Status' , dataKey: "status", align:"text-center", key:5, checked: true, canChange: true},
        {title: 'Intake' , dataKey: "intake", align:"text-center", key:6, checked: true, canChange: true},
        // {title: 'Created At' , dataKey: "created_at", align:"text-center", key:7, checked: true, canChange: true},
        {title: '' , dataKey: "", align:"text-center", key:8, checked: true, canChange: true},
        {title: '' , dataKey: "", align:"text-center", key:9, checked: true, canChange: true},
    ]);

    useEffect( () => {
        getEduApplications();
    }, [currentPage, perPage]);

    const getEduApplications = () => {
        let url = `p-educationApps`;
        console.log(url, currentPage, perPage);
        view_ApiManager(url, currentPage, perPage,(res) => {
            console.log('edu applications',res);
            setAllEduApps(res.data.data['data']);
            setPerPage(res.data.data['per_page']);
            setLastPage(res.data.data['last_page']);
            setTotalItem(res.data.data['total']);
            setPerItem(res.data.data['data'].length);
            setEduIndex(currentPage * perPage - perPage + 1);
            let options = res.data.data['data'].map(function (item) {
                return { 
                  index: item.index,
                  name: item.clientname,
                  coursename: item.coursename,
                  providername: item.providername,
                  datelodged: item.datelodged,
                  rfidate: item.rfidate,
                  status: item.status,
                  statuschangedate: item.statuschangedate,
                  created_at: moment(item.created_at).format('YYYY-MM-D'),
                  intake: item.intake,
                };
              })
              setSelectedEdu(options);
        },(err) => {
            console.log(err);
        });
    }
    const sorting = (col) => {
        const sortResult = Sorting(allEduApps, col, order)
        setOrder(sortResult[0]);
        setAllEduApps(sortResult[1]);
    }
    const handleModelCallback = (type) => {
        if(type === 'edit'){
            getEduApplications();
        }
        setShowModel(false)
    }
    const handleEditModelCallback = (type, value, name) =>{
        if(type === 'close'){
            setShowEditModel(false)
        } else if(type === 'edit'){
            updateApp(value);
        } else{
            if(name === 'provider'){
                setProviderId(value);
                setCourseType('')
                setIntakeId('')
                setCourseId("")
                setPackageId("")
                setIntakeId('')
                setPackageBundle([])
            } else if(name === 'type'){
                setCourseType(value)
                getCoursePackage(value)
                setCourseId("")
                setPackageId("")
                setIntakeId('')
                setPackageBundle([])
            } else if(name === 'course'){
                setCourseId(value)
                setIntakeId('')
                setPackageId();
                getCourseIntake(value, providerid);
            } else if(name === 'intake'){
                setIntakeId(value);
            } else if(name === 'package'){
                setPackageId(value)
                getBundles(value)
                setCourseId('')
                setIntakeId('')
            } else if(name === 'notes'){
                setNotes(value);
            }
        }
    }
    const updateApp = (appid) => {
        const data = {
            coursetype: coursetype,
            providerid: providerid,
            notes: notes,
            clientid: editAppData['clientid'],
            packageid: packageid,
            courseid: courseid,
            intake: intakeid,
            appid: appid,
        }
        console.log(data);
        let url = `p-updateEduApp`;
        post_ApiManager(data, url, (res) => {
            console.log('edited',res);
            if(res.data.error) swal(" ", res.data.error, "error")
            else {
                swal("Success!", res.data.message, "success");
                getEduApplications(); 
                setShowEditModel(false);
                resetValues();
               }  
        },(err) => {
            console.log(err);
        }); 
    }
     const getClients = () => {
        let url = `p-allClients`;
        console.log(url);
        get_ApiManager('', url, (res) => {
          console.log('all-clients', res);
          setAllClients(res.data[0]);
        }, (err) => {
          console.log(err);
        });
    }
    const getProviders = () => {
        let urls = 'getProviders';
        get_ApiManager('', urls,(res) => {
            console.log('providers',res);
            setAllProviders(res.data.data);
        },(err) => {
            console.log(err);
        })
    }
    const createEdu = () => {
        getClients();
        getProviders();
        setShowModel(true)
    }
    const handlePaginationCallback = (i, perPage) => {
        if(perPage && perPage != ''){
            setCurrentPage(1);
            setPerPage(perPage); 
        } else { setCurrentPage(i);  }
    }
    const editApp = (appid) => {
        let url = `p-getEduAppInfo/${appid}`;
        get_ApiManager('', url,(res) => {
            console.log('edu-info',res);
            const detail = res.data.data;
            setEditAppData(res.data.data);
            setCourseType(res.data.data['coursetype']);
            setProviderId(res.data.data['providerid'])
            getClients();
            getProviders();
            if(res.data.data['coursetype'] === 'single'){
                getCourses(res.data.data['providerid']);
                getCourseIntake(res.data.data['courseid'],res.data.data['providerid']) 
                setCourseId(res.data.data['courseid'])
                setIntakeId(res.data.data['intakeid'])
            }else if(detail['coursetype'] === 'package' && detail['packageid']){
                getPackages(res.data.data['providerid']);
                setPackageId(detail['packageid'])
                getBundles(detail['packageid']);
            }
            setNotes(res.data.data['notes'])
            setShowEditModel(true)
        },(err) => {
            console.log(err);
        }) 
    }
    const getBundles = (packageid) => {
        let url = 'getPkgCourses';
        get_ApiManager(packageid, url,(res) => {
            console.log('package-bundle',res);
            setPackageBundle(res.data.data['courses'])
            setSubPackages(res.data.data['subcourses'])
        },(err) => {
            console.log(err);
        })
    }
    const getCourses = (providerid) =>{
        let url = 'getCourseByNames';
        get_ApiManager(providerid, url,(res) => {
            console.log('all courses',res);
            setAllCourses(res.data.data);
        },(err) => {
            console.log(err);
        }) 
    }
    const getCourseIntake = (course, provider) => {
        let url = `courseIntakes/${course}/${provider}`;
        console.log(url);
        get_ApiManager('', url,(res) => {
            console.log('intakes',res);
            setIntakes(res.data.data);
        },(err) => {
            console.log(err);
        })
    }
    const getPackages = (providerid) => {
        let url = 'getPackagesByNames';
        get_ApiManager(providerid, url,(res) => {
            console.log('packages',res);
            setAllPackages(res.data.data);
        },(err) => {
            console.log(err);
        })  
    }
    const getCoursePackage = (type) => {
        if(type === 'single'){
            getCourses(providerid);
        } else if(type === 'package'){
            getPackages(providerid);
        }
    }
    const resetValues = () =>{
        setPackageId('');
        setCourseId('');
        setIntakeId('');
        setCourseId('');
        setProviderId('');
        setNotes('');
    }

    return(
        <>
        <div className='row px-4 pb-4 pt-2'>
        <div className='d-flex my-3'>
        <button className='text-left btn btn-main mx-2' onClick={() => createEdu()}>Create Application</button>
        </div>

        <div className='mt-2'>
        <table className={`table table-bordered bg-white mb-1`}>
        <thead className="thead-dark">
         <tr>
         {headers.map(head => ( head.checked &&
         <th id={head.dataKey} className={head.align} key={head.key} onClick={() => sorting(head.dataKey)}> {head.title}</th> ))}
         </tr>
         </thead>
         <tbody>
         {allEduApps.map((value, index) => (
            <tr key={value.id}>
            <td className='text-center'>{++index}</td>
            <td className='text-center'>{value.clientname}</td>
            <td className='text-center'>
            {value.coursetype === 'single' ? value.coursename : value.coursetype === 'package' ? value.packagename : ''}</td>
            <td className='text-center'>{value.providername}</td>
            <td className='text-center'>{value.status}</td>
            <td className='text-center'>{value.intake}</td>
            {/* <td className='text-center'>{moment(value.created_at).format('D MMMM YYYY')}</td> */}
            <td className='text-center'>
            <Link to={`/education-appplication-detail/${value.id}`} className="icon-link">
            <div className="icon-container"> 
            <FaEye className="eye-icon" style={{color:'grey'}} /> 
            <span className="hover-text">View</span>
            </div> 
            </Link></td>
            <td className='text-center'>
            <div className="icon-container" onClick={() => editApp(value.id)}> 
            <FaEdit className="eye-icon" style={{color:'grey'}} /> 
            <span className="hover-text">Edit</span>
            </div> 
            </td>
            </tr>))}
         </tbody>
         </table>
        </div>
        <Paginations
         currentPage={currentPage}
         total={totalItem} 
         itemsPerPage= {perPage}
         lastPage={lastPage}
         totalItems={totalItem}
         perItem={perItem}
         onPageChange={handlePaginationCallback} 
        />
         <div className="d-flex mb-5">
         <ExportOptions
         pdffilename={'EducationApps.pdf'} 
         csvfilename={'EducationApps.csv'} 
         xlsxfilename={'EducationApps'} 
         header="EducationApps List"
         id="viewclientsdiv3" 
         columns={headers}
         data={selectedEdu} />
         </div>

        </div>

         <CreateEduApp
         show={showModel}
         allClients={allClients}
         allProviders={allProviders}
         parentCallback={handleModelCallback}
         />
         <EditEduApp 
          show={showEditModel}
          allClients={allClients}
          allProviders={allProviders}
          data={editAppData}
          subPackages={subPackages}
          packageBundle={packageBundle}
          allCourses={allCourses}
          allPackages={allPackages}
          intakes={intakes}
          coursetype={coursetype}
          providerid={providerid}
          courseid={courseid}
          notes={notes}
          packageid={packageid}
          parentCallback={handleEditModelCallback}
         />
        </>
    )
}
export default AllEducationApps