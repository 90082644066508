


export const Sorting = (array, col, order) => {
    if(col){
        if(order === 'ASC'){
            const sorted = [...array].sort((a,b)=>(
            a[col] && b[col] ? 
            a[col] > b[col] ? 1 : -1 :
            !a[col] ? 1 : -1 ));
            document.getElementById(col).classList.remove('desc');
            document.getElementById(col).classList.add('asc');
            return ['DEC', sorted];
        } else if(order === 'DEC'){
            const sorted = [...array].sort((a,b)=>(
            a[col] && b[col] ? 
            a[col] < b[col] ? 1 : -1 :
            !b[col] ? 1 : -1));
            document.getElementById(col).classList.remove('asc');
            document.getElementById(col).classList.add('desc');
            return ['ASC', sorted];
        }
    }
}
