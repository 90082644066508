
import React from "react";
import * as FaIcons from 'react-icons/fa';
import { useNavigate} from "react-router-dom";


const Title = (props) => {
    const history = useNavigate();

    return(
        <>
        {props.back && !props.btn ? 
        <div className="p-1 row">
        <div className="col-lg-1 text-left pl-0"> <span className="pb-2 pt-0">
            <a className="back-icon pl-lg-2" onClick={() => props.backUrl ? history( '/main-settings') : history(-1)}>
            <FaIcons.FaArrowAltCircleLeft size={18}/><span className="back-text pl-lg-1">back</span>
            </a></span>
        </div>
        <div className="col-lg-11">
          <h5 className={"text-center font-monst mb-0"}>{props.title}</h5>
        </div>
        </div>
        :
        <div className="p-1" >
        <div className="row pr-2">
            {props.back && 
            <span className="pt-0 col-11">
            <a className="back-icon pl-lg-2" onClick={() => props.backUrl ? history( '/main-settings') : history(-1)}>
            <FaIcons.FaArrowAltCircleLeft size={18}/><span className="back-text pl-lg-1">back</span>
            </a></span>
            }
            <h5 className={`text-left font-monst ${!props.back ? 'pt-1' : ' pl-lg-3' } ${props.titleCol ? props.titleCol : 'col-lg-4'}`}>{props.title}</h5>
            {props.btn ? 
            <div className={`${props.btnCol ? props.btnCol : 'col-lg-6'}`}>
            <button  type="button"  
            onClick={() => props.btnUrl ? history(props.btnUrl) : props.parentCallback() }
            className={`${props.btnClass ? props.btnClass: ''}`}>{props.btnText}</button>
            </div>
            : ''}
        </div>
        </div>
        }
        </>
    ) 
}
export default Title;