
import React, { Component } from 'react';
import { Modal, Button } from "react-bootstrap";
import {get_ApiManager, post_ApiManager } from "../ApiManager";
import swal from 'sweetalert';

class CreateEduApp extends Component {
    constructor(props) {
        super(props);
        this.state = {
            client:'',
            provider:'',
            type:'',
            pCourses:[],
            pIntakes:[],
            pPackages:[],
            packageBundle:[],
            subPackages:[],
            courseid:'',
            intakeid:'',
            packageid:'',
        }
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleOptionChange = this.handleOptionChange.bind(this);
    }
    // call on cancel
    handleModal = () => {
         this.setState({client:'', provider:'', type:'', pCourses:[], pIntakes:[], pPackages:[], packageBundle:[], subPackages:[], courseid:'', intakeid:'', packageid:''})
         this.props.parentCallback(false); 
    } 
    // onchange
    handleChange(event) {
        this.setState({
            [event.target.name]: event.target.value
        });
        if(event.target.name === 'provider'){
            this.setState({type:'',courseid:'', packageid:'', pCourses:[], pIntakes:[]})
        }
    }
    // on submit button
    handleSubmit() {
        let url = `p-createEduApp`;
        if(this.state.type === 'single'){
            if(!this.state.intakeid){
                swal(" ", 'Please select intake', "info")
                return false;
            }
        }
        const data = {
            coursetype: this.state.type,
            providerid : this.state.provider,
            notes: this.state.note,
            clientid:  this.state.client,
            packageid: this.state.packageid,
            courseid: this.state.courseid,
            intake: this.state.intakeid,
        }
        console.log(data);
        post_ApiManager(data, url, (res) => {
            console.log('app-created',res);
            if(res.data.error) swal(" ", res.data.error, "error")
            else {
                swal("Success!", res.data.message, "success"); 
                this.setState({client:'', provider:'', type:'', pCourses:[], pIntakes:[], pPackages:[], packageBundle:[], subPackages:[], courseid:'', intakeid:'', packageid:''})
                this.props.parentCallback('edit');
               }  
        },(err) => {
            console.log(err);
        }); 
    }
    handleOptionChange(e) {
        this.setState({
            [e.target.name]: e.target.options[e.target.selectedIndex].text
        });
    }
    getCourses = () =>{
        let url = 'getCourseByNames';
        get_ApiManager(this.state.provider, url,(res) => {
            console.log('all courses',res);
            this.setState({pCourses: res.data.data, packageid:''});
        },(err) => {
            console.log(err);
        }) 
    }
     getCoursePackage = async (e, type) => {
        e.preventDefault();
        this.setState({type: type})
        if(type === 'single'){
            this.getCourses(this.state.provider);
        } else if(type === 'package'){
            this.getPackages(this.state.provider);
        }
    }
       // Get course intake list
     getCourseIntake = (e, course) => {
        console.log(course)
        e.preventDefault();
        let url = `courseIntakes/${course}/${this.state.provider}`;
        console.log(url);
        get_ApiManager('', url,(res) => {
            console.log('intakes',res);
            this.setState({pIntakes: res.data.data, courseid: course})
        },(err) => {
            console.log(err);
        })
    }
     getPackages = () =>{
        let url = 'getPackagesByNames';
        get_ApiManager(this.state.provider, url,(res) => {
            console.log('packages',res);
            this.setState({pPackages: res.data.data, courseid:''})
        },(err) => {
            console.log(err);
        })  
    }
    checkPackages = (packageID) => {
        console.log(packageID)
        this.setState({packageid: packageID})
        let url = 'getPkgCourses';
        get_ApiManager(packageID, url,(res) => {
            console.log('package-bundle',res);
            this.setState({packageBundle : res.data.data['courses'], subPackages: res.data.data['subcourses']})
        },(err) => {
            console.log(err);
        })
    }
    
  render(){ 
    return(
        <>
         <Modal size="lg" className="p-modal-content" show={this.props.show} onHide={this.handleModal}>  
            <Modal.Header closeButton><div className="text-center col-11 size-18 font-monst">Admission Application</div></Modal.Header>  
            <Modal.Body className="ml-2 mr-2">
            <form action='' onSubmit={this.handleSubmit}>
            <div className='row px-3 mb-3 justify-content-center'>

            <div className='col-lg-8'>
             <label className="acc-label2 size-18 mb-0">Client</label>
             <select className="form-select app-form acc-form" onChange={this.handleChange} aria-label="form-select-lg example" name="client">
             <option selected>Please select</option>
             {this.props.allClients.map(value => 
             <option value={value.id}>{value.name}</option>
             )}
             </select>
             </div>

             {this.state.client &&
             <div className='col-lg-8 mt-4'>
             <label className="acc-label2 size-18 mb-0">Provider</label>
             <select className="form-select app-form acc-form" name="provider" onChange={this.handleChange}>
             <option selected>Please Select</option>
             {[].concat(this.props.allProviders).sort((a, b) => a.name > b.name ? 1 : -1).map((value, index) => 
             <option key={index} value={value.id}>{value.name}</option>
             )}
             </select>
             </div>}

             {this.state.provider &&
             <div className='col-lg-8 mt-4'>
             <label className="acc-label2 size-18 mb-0">Select Course Type</label>
             <select value={this.state.type} className="form-select app-form acc-form" onChange={(e) => this.getCoursePackage(e, e.target.value)} aria-label="form-select-lg example" name="client">
             <option value="">Please select</option>
             <option value='single'>Single</option>
             <option value='package'>Package</option>
             </select>
             </div>}

             {this.state.type === 'single' &&
             <div className="col-lg-8 mt-4">
             <label className="acc-label2 size-18 mb-0">Courses</label>
             <select className="form-select app-form acc-form" name="courseid" aria-label="form-select-lg" onChange={(e) => this.getCourseIntake(e, e.target.value)}>
             <option selected>Select Course</option>
             {[].concat(this.state.pCourses).sort((a, b) => a.name > b.name ? 1 : -1).map(value => 
             <option key={value.id} value={value.id}>{value.name}</option> )}
             </select>
             </div>}

             {this.state.courseid &&
             <div className="col-lg-8 mt-4">
                <label className="acc-label2 size-18 mb-0">Course Intake</label>
                <select className="form-select app-form acc-form" name="intakeid" onChange={this.handleChange} aria-label="form-select-lg">
                <option selected disabled>Please Select</option>
                {[].concat(this.state.pIntakes).sort((a, b) => a.title > b.title ? 1 : -1).map(value => 
                <option key={value.id} value={value.intake_id}>{value.title}</option> )}
                </select>
              </div>}

             {this.state.type === 'package' &&
             <div className="col-lg-8 mt-4">
             <label className="acc-label2 size-18 mb-0">Packages</label>
             <select className="form-select app-form" 
             onChange={(e) => this.checkPackages(e.target.value)} aria-label="form-select-lg">
             <option value="">Please Select</option>
             {[].concat(this.state.pPackages).sort((a, b) => a.name > b.name ? 1 : -1).map(value => 
             <option key={value.id} value={value.id}>{value.name}</option> )}
             </select>
             </div>}

             {this.state.packageBundle[0] && 
             <div className="col-lg-8 mt-3">
             <div className="form-group">
             <ul className="pl-4 border mt-lg-2 list-group">
                {this.state.packageBundle.map((value, key) => 
                    <li key={key} className="acc-label2 p-1">{value.name}</li>
                )}
                {this.state.subPackages &&
                <>
                {this.state.subPackages.map(sub => {
                    return(
                    <>
                    {sub.map(s => ( <li className="acc-label2 p-1" key={s.id}> {s.name} </li>))}
                    </>
                )})}
                </>}
             </ul>
          </div>
             </div>}

             <div className="col-lg-8 mt-4">
             <label className="acc-label2 mb-1">Notes</label>
             <textarea className="textarea border pl-2 app-form acc-form" name="note" onChange={this.handleChange}
             placeholder="Please enter..." rows="4" cols="60"></textarea>
             </div>


             </div>
            </form>
            <Modal.Footer>
            {(this.state.provider && (this.state.courseid || this.state.packageid)) &&
            <Button className="btn btn-success col-12" onClick={() =>  this.handleSubmit()}>Submit</Button>}  
            <Button className="btn btn-danger col-2" onClick={()=>this.handleModal()}>Close</Button>  
            </Modal.Footer>
            </Modal.Body>
            </Modal>
        </>
    )
  }
}
export default CreateEduApp;