// Header.js
import React, { useEffect } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import './header.css'; // Make sure to create a corresponding CSS file
import { FaAvianex, FaHome, FaInbox, FaSchool } from 'react-icons/fa';
import logo from '../../assets/logo1.png';
import { logout_ApiManager } from '../ApiManager';
import swal from 'sweetalert';
import { removeUserSession } from '../utilies/Common';

const Header = (props) => {
    const user = JSON.parse(localStorage.getItem('agent'));
    const navigate = useNavigate();

    useEffect(() => {
    }, []);
  
    const handleLogout = () => {
      swal({
        title: "Logging out",
        text: "Are you sure to log out?",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then((willDelete) => {
        if (willDelete) {
          removeUserSession();
          // logout user when password changed
          logout_ApiManager((res) => {
            console.log('logging out', res);
            swal({
              title: "Success!",
              text: 'Logged out!!',
              icon: "success",
              timer: 1000,
              buttons: false, // Disable buttons to automatically close after the timer
            }).then(() => {
              localStorage.removeItem('token');
              navigate('/login'); // Navigate after swal has finished
            });
          });
        }
      });
    };
  
  return (
    user && user.agentname ?
    <header className="header row align-items-stretch px-4">
    <div className='row px-2'>
    <div className='row mb-2'>
    <div className='col-lg-4'>
      <div id="logo" className="sidebar-brand-text"> 
      <img src={logo} alt="Company logo" width="130"/>
      </div>
    </div>
    <div className='col-lg-5'>
    <div className='d-flex px-3'>
    <div className='size-16 '>RM Contact Details:&nbsp;&nbsp;</div>
    <div className='size-15 bold' style={{color:'red'}}>&nbsp;{user['manager']['name']}<br/>
    {user['manager']['mobile']}</div>
    </div>
    </div>
    <div className='col-lg-3'>
    <div className='d-flex justify-content-right'>
    <NavLink to="/my-profile"  className={({ isActive }) => isActive ? "icon-button svg active-tab" : "icon-button svg"}>
    <img className="profile-icon" alt="" height="42" src="https://api.aptitudemigration.com.au/public/profile_images/user.svg" />
    </NavLink>
    <div className="black-btn pl-1">
        <h6 className="mb-0 mt-1 size-14 bold pr-3">{user['agencyname']}</h6>
        <NavLink to="/my-profile" className={({ isActive }) => isActive ? "black-btn active-tab" : "black-btn"} >
        <small className='bold size-14 underline'>View Profile</small>
      </NavLink>
      </div>
    <div className='pl-lg-4'>
    <button className='acc-label size-14 btn btn-dark' onClick={handleLogout}>Logout</button>
    </div>
    </div>
    </div>
    </div>
    <div className='row mt-3'>
    <div className="menu size-12 white custom-font4 w-100 mb-2">
      <NavLink to="/dashboard" className={({ isActive }) => isActive ? "no-underline menu-item active-tab" : "no-underline menu-item"}>
        <FaHome size={14} className='dash-icon' />
        <span className='pl-lg-2 pl-1'>Dashboard</span>
      </NavLink>
      <NavLink to="/client-list" className={({ isActive }) => isActive ? "no-underline menu-item active-tab" : "no-underline menu-item"}>
        <FaSchool size={14} className='dash-icon' />
        <span className='pl-lg-2 pl-1'>Clients</span>
      </NavLink>
      <NavLink to="/education-apps" className={({ isActive }) => isActive ? "no-underline menu-item active-tab" : "no-underline menu-item"}>
        <FaSchool size={14} className='dash-icon' />
        <span className='pl-lg-2 pl-1'>Admission Application</span>
      </NavLink>
      <NavLink to="/migration-apps" className={({ isActive }) => isActive ? "no-underline menu-item active-tab" : "no-underline menu-item"}>
        <FaAvianex size={14} className='dash-icon' />
        <span className='pl-lg-2 pl-1'>Visa Application</span>
      </NavLink>
      <NavLink to="/inbox" className={({ isActive }) => isActive ? "no-underline menu-item active-tab" : "no-underline menu-item"}>
        <FaInbox size={14} className='dash-icon' />
        <span className='pl-lg-2 pl-1'>Inbox
        {props.count > 0 && <span className="bell-text"><small className="bell-text1 px-2 ml-1">{props.count}</small></span>}</span>
      </NavLink>
      {/* <NavLink to="/payment" className="no-underline menu-item" activeClassName="active-tab">
        <FaFileInvoiceDollar size={14} className='dash-icon' />
        <span className='pl-lg-2 pl-1'>Payment</span>
      </NavLink> */}
    </div>
      </div>
    </div>

    </header>:''
  );
};

export default Header;
