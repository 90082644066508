
import React, { Component } from 'react';
import { Modal, Button } from "react-bootstrap";

class EditMigApp extends Component {
    constructor(props) {
        super(props);
        this.state = {
            client:'',
            provider:'',
            type:'',
            courseid:'',
        }
        this.handleChange = this.handleChange.bind(this);
        this.handleOptionChange = this.handleOptionChange.bind(this);
    }
     // call on cancel
     handleModal = () => {
         this.props.parentCallback(false); 
    } 
     // onchange
     handleChange(event) {
        this.setState({
            [event.target.name]: event.target.value
        });
    }
  
     handleOptionChange(e) {
        this.setState({
            [e.target.name]: e.target.options[e.target.selectedIndex].text
        });
    }
    
  render(){ 
    return(
        <>
         <Modal size="lg" className="p-modal-content" show={this.props.show} onHide={() => this.props.parentCallback('close')}>  
            <Modal.Header closeButton><div className="text-center col-11 size-18 font-monst">Visa Application</div></Modal.Header>  
            <Modal.Body>
            <form action=''>
            <div className='row px-3 mb-3 justify-content-center'>

            <div className='col-lg-8'>
             <label className="acc-label2 size-18 mb-0">Client</label>
             <select value={this.props.data['clientid'] || ""} className="form-select app-form acc-form" onChange={this.handleChange} aria-label="form-select-lg example" name="client" disabled>
             <option>Please select</option>
             {this.props.allClients.map(value => 
             <option value={value.id}>{value.name}</option>
             )}
             </select>
             </div>

             <div className='col-lg-8 mt-3'>
             <label className="acc-label2 size-18 mb-0">Select Category</label>
             <select value={this.props.categoryid || ""} className="form-select app-form acc-form" onChange={(e) => this.props.parentCallback('category', e.target.value)} aria-label="form-select-lg example" name="category">
             <option>Please select</option>
             {this.props.categories.map((value, index) => 
             <option value={value.id} key={index}>{value.name}</option>
             )}
             </select>
             </div>

             {this.props.categoryid &&
             <div className='col-lg-8 mt-3'>
             <label className="acc-label2 size-18 mb-0">Select Provider</label>
             <select value={this.props.providerid || ""} className="form-select app-form acc-form" name="provider" onChange={(e) => this.props.parentCallback('provider', e.target.value)}>
             <option>Please Select</option>
             {[].concat(this.props.providers).sort((a, b) => a.name > b.name ? 1 : -1).map((value, index) => 
             <option key={index} value={value.id}>{value.name}</option>
             )}
             </select>
             </div>}

             {this.props.providerid &&
             <div className='col-lg-8 mt-3'>
             <label className="acc-label2 size-18 mb-0">Select Visa Type</label>
             <select value={this.props.visaid || ""} className="form-select app-form acc-form" name="courseid" aria-label="form-select-lg" onChange={(e) => this.props.parentCallback('visa', e.target.value)}>
             <option>Select Course</option>
             {[].concat(this.props.visaTypes).sort((a, b) => a.name > b.name ? 1 : -1).map(value => 
             <option key={value.id} value={value.id}>{value.name}</option> )}
             </select>
             </div>}

             <div className="col-lg-8 mt-2">
             <label className="acc-label2 mb-1">Notes</label>
             <textarea value={this.props.notes} className="textarea border pl-2 app-form acc-form" name="note" onChange={(e) => this.props.parentCallback('notes', e.target.value)}
              placeholder="Please enter..." rows="4" cols="60"></textarea>
             </div>


             </div>
            </form>
            <Modal.Footer>
            {(this.props.providerid && this.props.visaid) &&
            <Button className="btn btn-success col-12" onClick={() => this.props.parentCallback('edit', this.props.data['appid'])}>Update</Button>}  
            <Button className="btn btn-danger col-2" onClick={()=>this.props.parentCallback('close')}>Close</Button>  
            </Modal.Footer>
            </Modal.Body>
            </Modal>
        </>
    )
  }
}
export default EditMigApp;