
import React, { Component } from 'react';
import { Modal, Button } from "react-bootstrap";
import {get_ApiManager, post_ApiManager } from "../ApiManager";
import swal from 'sweetalert';

class CreateMigApp extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }
    
  render(){ 
    return(
        <>
         <Modal size="lg" className="p-modal-content" show={this.props.show} onHide={() => this.props.parentCallback('close')}>  
            <Modal.Header closeButton><div className="text-center col-11 size-18 font-monst">Visa Application</div></Modal.Header>  
            <Modal.Body className="ml-2 mr-2">
            <form action='' onSubmit={this.handleSubmit}>
            <div className='row px-3 mb-3 justify-content-center'>

            <div className='col-lg-8'>
             <label className="acc-label2 size-18 mb-0">Select Client</label>
             <select className="form-select app-form acc-form" onChange={(e) => this.props.parentCallback('client', e.target.value)} aria-label="form-select-lg example" name="category">
             <option>Please select</option>
             {this.props.allClients.map((value, index) => 
             <option value={value.id} key={index}>{value.name}</option>
             )}
             </select>
             </div>

            <div className='col-lg-8 mt-3'>
             <label className="acc-label2 size-18 mb-0">Select Category</label>
             <select className="form-select app-form acc-form" onChange={(e) => this.props.parentCallback('category', e.target.value)} aria-label="form-select-lg example" name="category">
             <option>Please select</option>
             {this.props.categories.map((value, index) => 
             <option value={value.id} key={index}>{value.name}</option>
             )}
             </select>
             </div>

             {this.props.categoryid &&
             <div className='col-lg-8 mt-3'>
             <label className="acc-label2 size-18 mb-0">Select Provider</label>
             <select className="form-select app-form acc-form" name="provider" onChange={(e) => this.props.parentCallback('provider', e.target.value)}>
             <option>Please Select</option>
             {[].concat(this.props.providers).sort((a, b) => a.name > b.name ? 1 : -1).map((value, index) => 
             <option key={index} value={value.id}>{value.name}</option>
             )}
             </select>
             </div>}

             {this.props.providerid &&
             <div className='col-lg-8 mt-3'>
             <label className="acc-label2 size-18 mb-0">Select Visa Type</label>
             <select className="form-select app-form acc-form" name="courseid" aria-label="form-select-lg" onChange={(e) => this.props.parentCallback('visa', e.target.value)}>
             <option>Select Course</option>
             {[].concat(this.props.visaTypes).sort((a, b) => a.name > b.name ? 1 : -1).map(value => 
             <option key={value.id} value={value.id}>{value.name}</option> )}
             </select>
             </div>}

             <div className="col-lg-8 mt-3">
             <label className="acc-label2 mb-1">Notes</label>
             <textarea className="textarea border pl-2 app-form acc-form" name="note" onChange={(e) => this.props.parentCallback('note', e.target.value)}
             placeholder="Please enter..." rows="4" cols="60"></textarea>
             </div>

             </div>
            </form>
            <Modal.Footer>
            {(this.props.clientid && this.props.categoryid && this.props.providerid && this.props.visaid) &&
            <Button className="btn btn-success col-12" onClick={() => this.props.parentCallback('edit')}>Submit</Button>}  
            <Button className="btn btn-danger col-2" onClick={() => this.props.parentCallback('close')}>Close</Button>  
            </Modal.Footer>
            </Modal.Body>
            </Modal>
        </>
    )
  }
}
export default CreateMigApp;