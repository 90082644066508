import { faPlus, faRefresh, faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import swal from 'sweetalert';
import { Nav, Tab } from 'react-bootstrap';
import './inbox.css'
import SendInboxEmail from '../GenericComponents/SendInboxEmail';
import { view_ApiManager } from '../ApiManager';
import moment from 'moment';
import { FaAngleLeft, FaAngleRight } from 'react-icons/fa';
import EmailDetailComponent from './EmailDetail';
import { Navigate, useNavigate } from 'react-router-dom';


const Inbox = (props) => {
    const [sendModel, setSendModel] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [lastPage, setLastPage] = useState('');
    const [totalItem, setTotalItem] = useState('');
    const [perPage, setPerPage] = useState(10);
    const [perItem, setPerItem] = useState('');
    const [from, setFrom] = useState('');
    const [to, setTo] = useState('');
    const [allEmail, setAllEmail] = useState([]);
    const navigate = useNavigate();

    useEffect( () => {
        getChat();
    }, [currentPage, perPage]);

    const handleNotification = () => {
        const socket = props.socket;
        socket.emit("sendChatNotification");
    }
    const getChat = () => {
        let url = `p-getChat`;
        view_ApiManager(url, currentPage, perPage,(res) => {
            console.log('p-emails',res);
            setAllEmail(res.data.data['data'])
            setPerPage(res.data.data['per_page']);
            setLastPage(res.data.data['last_page']);
            setTotalItem(res.data.data['total']);
            setPerItem(res.data.data['data'].length);
            setFrom(res.data.data['from']);
            setTo(res.data.data['to']);
        },(err) => {
            console.log(err);
        });
    }
    const handleEmailCallback = (type) => {
        if(type === 'edit'){
            handleNotification();
        }
        setSendModel(false)
     }
    const handleEmailClick = (emailId) => {
        navigate(`/email/${emailId}`);
    };
    const getSentEmail = () => {
        let url = `p-getSentChat`;
        view_ApiManager(url, currentPage, perPage,(res) => {
            console.log('p-emails',res);
            setAllEmail(res.data.data['data'])
            setPerPage(res.data.data['per_page']);
            setLastPage(res.data.data['last_page']);
            setTotalItem(res.data.data['total']);
            setPerItem(res.data.data['data'].length);
            setFrom(res.data.data['from']);
            setTo(res.data.data['to']);
        },(err) => {
            console.log(err);
        }); 
    }
    const getUnreadEmail = () => {
        let url = `p-getUnreadChat`;
        view_ApiManager(url, currentPage, perPage,(res) => {
            console.log('unread-emails',res);
            setAllEmail(res.data.data['data'])
            setPerPage(res.data.data['per_page']);
            setLastPage(res.data.data['last_page']);
            setTotalItem(res.data.data['total']);
            setPerItem(res.data.data['data'].length);
            setFrom(res.data.data['from']);
            setTo(res.data.data['to']);
        },(err) => {
            console.log(err);
        }); 
    }
    const handleTabSelect = (selectedKey) => {
        if(selectedKey === 'all'){
            getChat();
        } else if(selectedKey === 'sent'){
            getSentEmail();
        } else if(selectedKey === 'unread'){
            getUnreadEmail();

        }
        console.log(`Selected tab: ${selectedKey}`);
        // You can add any function call or logic here that you want to execute on tab change
    };
     
    return(
        <>
        <div className='row p-4' style={{ height: '100vh' }}>
        <div className='col-lg-2 col-12 col-md-5 col-xxl-2 form-bg px-2 bg-white area1 py-3 '>
        <div className='row'>
        <button className='btn btn-secondary' onClick={()  => setSendModel(true)}>
        <FontAwesomeIcon className="pr-2" icon={faPlus} />Send Message</button>
        </div>
        </div>
        <div className='col-lg-10 col-12 col-md-7 col-xxl-10 bg-white no-left-border'>
        {/* <div className='row pb-0'>
        <div className="input-group">
        <div className="input-group-prepend">
            <span className="input-group-text" id="basic-addon1" style={{borderRadius:'0px', background: '#6c757d', color: 'white'}}>
                <FontAwesomeIcon icon={faSearch} />
            </span>
        </div>
        <input type="text" placeholder="Search on emails..." className="no-border-input mx-1 my-1 w-75 form-control" aria-label="Search" aria-describedby="basic-addon1"  />
        </div>
        </div> */}

        <div className='row'>
        <Tab.Container id="left-tabs-example" defaultActiveKey="all" onSelect={handleTabSelect}>
        <Nav variant="underline" className='no-left-right-border px-2 py-1' >
        <Nav.Item><Nav.Link  eventKey="all">All Messages</Nav.Link></Nav.Item>
        <Nav.Item><Nav.Link  eventKey="sent">Sent</Nav.Link></Nav.Item>
        <Nav.Item><Nav.Link eventKey="unread">Unread</Nav.Link></Nav.Item>
        </Nav> 
        <Tab.Content>
            <Tab.Pane eventKey="all">
            <div className='d-flex py-2' style={{background:'#f2f6fc'}}>
            <FontAwesomeIcon className="px-2 pointer" icon={faRefresh} color='#5f5f5f'  onClick={() => getChat()}  />
            </div>
            {allEmail.map((value, index) => (
            <div className={`row px-3 py-3 no-left-right-border shadow-sm pointer ${value.is_read === 0 ? 'unread':'read'}`} 
            key={index} onClick={() => handleEmailClick(value.id)}>
            <div className='col-1'>{++index}</div>
            <div className='col-3'>
            Aptitude Migration 
            {value.counts > 0 && <small className='pl-2' style={{color:'grey'}}>{value.counts}</small>}
            {value.is_read === 0 && <>{moment(value.created_at).isSame(moment(), 'day') && (
                <div className="rounded-text-div">New</div>)}</>}
            </div>
            <div className='col-7'>{value.subject}</div>
            <div className='col-1 size-14'> {moment(value.created_at).isSame(moment(), 'day')
                ? moment(value.created_at).format('h:mm A') // Show time if today
                : moment(value.created_at).format('D MMM YYYY') // Show date otherwise
            }</div>
            </div>))}
            </Tab.Pane>
            <Tab.Pane eventKey="sent">
            {allEmail.map((value, index) => (
            <div className={`row px-3 py-3 no-left-right-border shadow-sm pointer read`} 
            key={index} onClick={() => handleEmailClick(value.id)}>
            <div className='col-1'>{++index}</div>
            <div className='col-3'>me
            {value.counts > 0 && <small className='pl-2' style={{color:'grey'}}>{value.counts}</small>}
            </div>
            <div className='col-7'>{value.subject}</div>
            <div className='col-1 size-14'> {moment(value.created_at).format('D MMM YYYY')}</div>
            </div>))}
            </Tab.Pane>
            <Tab.Pane eventKey="unread">
            {allEmail.map((value, index) => (
            <div className={`row px-3 py-3 no-left-right-border shadow-sm pointer ${value.is_read === 0 ? 'unread':'read'}`} 
            key={index} onClick={() => handleEmailClick(value.id)}>
            <div className='col-1'>{++index}</div>
            <div className='col-3'>
            Aptitude Migration 
            {value.counts > 0 && <small className='pl-2' style={{color:'grey'}}>{value.counts}</small>}
            {value.is_read === 0 && <>{moment(value.created_at).isSame(moment(), 'day') && (
                <div className="rounded-text-div">New</div>)}</>}
            </div>
            <div className='col-7'>{value.subject}</div>
            <div className='col-1 size-14'> {moment(value.created_at).isSame(moment(), 'day')
                ? moment(value.created_at).format('h:mm A') // Show time if today
                : moment(value.created_at).format('D MMM YYYY') // Show date otherwise
            }</div>
            </div>))}
            </Tab.Pane>
        </Tab.Content>
        </Tab.Container>
        <div className="row p-2">
            <div className='col-lg-6'>
            <div className="text-left justify-content-left">
            <select value={perPage} onChange={(e) => { setPerPage(e.target.value); getChat(); }}  
            className="form-select page-form col-2 text-center">
            <option value={5}>5</option>
            <option value={10}>10</option>
            <option value={25}>25</option>
            <option value={100}>100</option>
            <option value={200}>200</option>
            <option value={totalItem}>All</option>
            </select>
            </div>
            </div>
            <div className='col-lg-6'>
            <div className="text-right d-flex paginate j-content-right">{from ? from : 0}-{to ? to : 0} of {totalItem ? totalItem : 0}
            <button className="px-2 py-0 btn" onClick={() => { if (currentPage > 1) { setCurrentPage(currentPage - 1);}}}
            style={{ cursor: currentPage === 1 ? 'default' : 'pointer' }}>
            <FaAngleLeft color={currentPage === 1 ? '#adadadc7' : ''} size={15} /></button>
            <button className="px-2 py-0 btn" style={{ cursor: currentPage === lastPage ? 'default' : 'pointer' }}
            onClick={() => { if (currentPage < lastPage) { setCurrentPage(currentPage + 1); getChat(); } }}>
            <FaAngleRight color={currentPage === lastPage ? '#adadadc7' : ''} size={15} />
            </button>
            </div>
            </div>
            </div>
        </div>

        </div>
        </div>
        <SendInboxEmail 
        show={sendModel}
        parentCallback={handleEmailCallback}
        />
       
        </>
    )
}
export default Inbox