
import React, { Component } from 'react';
import { Modal, Button } from "react-bootstrap";
import { get_ApiManager, post_ApiManager} from "../ApiManager"
import swal from 'sweetalert';

class SendInboxEmail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            appidError:null, messageError:null,
            appid:'',
            allApps:[],
            showApps:false,
            type:'',
            message:'',
            appData:'',
            user: JSON.parse(localStorage.getItem('agent')) || {},
        }
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleModal(){
         this.props.parentCallback(this.props.show, false); 
    } 

    handleChange(event) {
        this.setState({
            [event.target.name]: event.target.value
        });
    }      
    getApps(e, type){
        e.preventDefault();
        this.setState({showApps: false})
        let url = `p-allApps/${type}`;
        console.log(url);
        get_ApiManager('', url, (res) => {
          console.log('apps', res);
          this.setState({allApps:res.data.data, showApps:true, type:type})
        }, (err) => {
          console.log(err);
        });
    }
    handleSubmit() {
        this.setState({appidError:null, messageError:null})
        if(this.state.type && !this.state.appid){
            this.setState({appidError: 'Please select Application!'})
            return false;
        } else if(!this.state.message){
            this.setState({messageError: 'Please enter message!'})
            return false;
        } else{
            let url = `p-sendEmailtoCms`;
            const data = {
                type: this.state.type,
                client: this.state.appData['clientname'],
                appid : this.state.appid,
                message:  this.state.message,
                agentid: this.state.user['id'],
                coursename: this.state.appData['coursetype'] === 'package' ?
                this.state.appData['packagename'] : this.state.appData['coursetype'] === 'single' ?
                this.state.appData['coursename'] : this.state.appData['coursename'],
                managerid: this.state.user['handling_manager'],
                provider: this.state.appData['providername'],
            }
            console.log(data);
            post_ApiManager(data, url, (res) => {
                console.log('email-sent',res);
                if(res.data.error) swal(" ", res.data.error, "error")
                else {
                    swal("Success!", res.data.message, "success"); 
                    this.setState({appid:'', allApps:[], showApps:false, type:'', message:'',})
                    this.props.parentCallback('edit');
                   }  
            },(err) => {
                console.log(err);
            }); 
        }
    }
    setValues = (e) => {
        const selectedAppid = e.target.value;
        const selectedApp = this.state.allApps.find(app => app.appid == selectedAppid);
        this.setState({appData: selectedApp, appid: selectedAppid})
        console.log(selectedApp)
      };
      
    
  render(){ 
      return(
          <>
            <Modal aria-labelledby="contained-modal-title-vcenter" centered  show={this.props.show} onHide={()=>this.handleModal()}>  
                <Modal.Header closeButton={this.props.endButton == true ? "" : true}><div className="text-center col-11 font-f-sans white"></div></Modal.Header>  
                <Modal.Body className="ml-2 mr-2">
                <form action='' onSubmit={this.handleSubmit}>
                <div className="row">

                <div className="form-group col-4" >
                <label className="profile-label ">Application Type</label>
                <select onChange={(e) => this.getApps(e, e.target.value)} className="form-select acc-form form-select-lg w-lg-90" aria-label="form-select-lg example">
                    <option value="">Please select</option>
                    <option value="edu">Education</option>
                    <option value="mig">Migration</option>
                </select>
                </div>

                {this.state.showApps ===  true &&
                <div className="form-group col-8" >
                <label className="profile-label ">Applications</label>
                <select onChange={(e) => this.setValues(e)} name="appid" className="form-select acc-form form-select-lg w-lg-90" aria-label="form-select-lg example">
                    <option value="">Please select</option>
                    {this.state.allApps.map((value)=> 
                    <option key={value.appid} value={value.appid}>
                    {value.coursetype ? value.coursetype === 'package' ? value.packagename: value.coursename : value.coursename}</option>
                    )}
                </select>
                {this.state.appidError && <><small className="error">{this.state.appidError}</small></>}
                </div>}

                <div className="form-group col-12" >
                <label className="profile-label">Message</label>
                <textarea className="textarea form-control acc-form" placeholder="Please Enter" 
                name="message" onChange={this.handleChange} rows="8" cols="60"></textarea>
                 {this.state.messageError && <><small className="error">{this.state.messageError}</small></>}
                </div>

                </div>

                </form>
                </Modal.Body>  
                <Modal.Footer>  
                <Button className="btn btn-danger" onClick={()=>this.handleModal()}>Cancel</Button>
                {this.state.message ? 
                <Button className="btn btn-success" onClick={this.handleSubmit}>Send Email</Button>  :''}
                </Modal.Footer>  
            </Modal>  
          </>
      )
  }
}
export default SendInboxEmail