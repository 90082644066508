

import React ,{useState,useEffect, useRef}from "react"
import moment from 'moment';
import { Link, useParams } from "react-router-dom"
import { get_ApiManager, post_ApiManager, view_ApiManager } from "../ApiManager"
import Title from "../GenericComponents/Title";
import { FaHome, FaInbox, FaLandmark, FaReply, FaUpload } from "react-icons/fa";
import Modals from "../GenericComponents/Modals";
import swal from 'sweetalert';
import Moment from "react-moment";
import DOMPurify from "dompurify";
import { eduFileType } from "../utilies/Constants";
import userlogo from '../../assets/chat.png';
import userlogo1 from '../../assets/chat2.png';
import SendEmailFromApp from "../GenericComponents/SendEmailFromApp";

const EduAppDetail = (props) => {
    const { appid } = useParams();
    const [info, setInfo] = useState([]);
    const [detail, setDetail] = useState([]);
    const [allNotes, setAllNotes] = useState([]);
    const [showNote, setShowNote] = useState(false);
    const [activeTab, setActiveTab] = useState('client');
    const [documentList , setDocumentList] = useState([{name: "", file: null, type:""}]);
    const [emailList , setEmailList] = useState([]);
    const [showReply, setShowReply] = useState(false);
    const [replyError, setReplyError] = useState(null);
    const [reply, setReply] = useState('');
    const [parentid, setParentId] = useState('');
    const [sendModel, setSendModel] = useState(false);
    const user = useState(JSON.parse(localStorage.getItem('agent_id')));

    useEffect( () => {
        getEduInfo();
        getDetail();
    }, []);

    const handleTabClick = (tab) => {
        if(tab === 'notes'){
            getEduNotes();
        } else if (tab === 'email'){
            getPartnerChat();
        }
        setActiveTab(tab);
    };
     const getEduInfo = () => {
        let url = `p-getEduAppInfo/${appid}`;
        get_ApiManager('', url,(res) => {
            console.log('edu-info',res);
            setInfo(res.data.data)
        },(err) => {
            console.log(err);
        }) 
     }
     const getDetail = () => {
        let url = `p-getEduDetail/${appid}/${activeTab}`;
        console.log(url)
        get_ApiManager('', url,(res) => {
            console.log('edu-detail',res);
            setDetail(res.data.data)
        },(err) => {
            console.log(err);
        }) 
    }
    const getPartnerChat = () => {
        let url = `getChatHistory/${appid}/${'edu'}`;
        console.log(url);
        get_ApiManager('', url,(res) => {
            console.log('chat',res)
            setEmailList(res.data.data)
        },(err) => {
            console.log(err);
        })
     }
    const getEduNotes = () => {
        let url = `p-getEduNotes/${appid}`;
        get_ApiManager('', url,(res) => {
            console.log('notes',res);
            console.log(res.data.data)
            setAllNotes(res.data.data)
        },(err) => {
            console.log(err);
        }) 
     }

     const handleSimpleNoteCallback = (check, type , note) => {
        if(type == 'edit'){
           if(!note){
                 swal({ text: "Please enter note!", icon: "warning", dangerMode: true })
           } else {
             saveNote(note)
           }
        } 
        setShowNote(false)
     }

     const saveNote = (note) => {
        const data = {
            note: note,
            appid: appid,
            type: 2,
            apptype:2,
            apptypefornotification : 1,
            notefor: 'edu',
            clientid: info['clientid'],
            notetype:'partner'
        }
        console.log(data);
        let url = 'p-addNote';
        post_ApiManager(data, url, (res) => {
            console.log('note-saved' , res);
            if(res.data.error) swal(" ", res.data.error, "error")
            else {
                getEduNotes();
                swal("Success!", res.data.message , "success"); 
                sendNotificationToCms();
            };   
        },(err) => {
            console.log(err);
        });
     }
     const sendNotificationToCms = () => {
        const socket = props.socket;
        const user = props.user;
        socket.emit("sendNotification", {
            senderName: user,
        });
     }
     const handleInputChange = (e, index, list) => {
        const { name, value, type, files } = e.target;
        console.log(index)
        if (list === 'document') {
            const updatedList = [...documentList];
            if (type === "file") {
                updatedList[index]['file'] = files[0]; // Handle file input
            } else {
                updatedList[index][name] = value; // Handle text input
            }
            setDocumentList(updatedList);
        }
    };

    const handleRemoveClick = (list, index) => {
        if (list === 'document') {
            const newList = [...documentList];
            newList.splice(index, 1);
            setDocumentList(newList);
        }
    };
    const handleAddClick = (list) => {
        if (list === 'document') {
            setDocumentList(prevDocumentList => [
                ...prevDocumentList,
                { name: "", file: null, type:"" }
            ]);
        }
    };
    const handleNotification = () => {
        const socket = props.socket;
        socket.emit("sendChatNotification");
    }
    const sendReply = () => {
        if(!reply){
            setReplyError('Enter your Reply!');
            return false
        } else{
            setReplyError('');
        }
        let url = `replyToCmsEmail`;
        const data = {
            parentid: parentid,
            reply: reply,
            senderid: user[0],
        }
        console.log(data);
        post_ApiManager(data, url, (res) => {
            console.log('reply-sent',res);
            if(res.data.error) swal(" ", res.data.error, "error")
            else {
                handleNotification(); setReply(''); setReplyError(null); setShowReply(false); setParentId('');
                getPartnerChat();
               }  
        },(err) => {
            console.log(err);
        }); 
     }
     const setReplyValues = (parent_id) => {
        setParentId(parent_id)
        setShowReply(true)
     }
     const handleEmailCallback = (type) => {
        if(type === 'edit'){
            handleNotification();
            getPartnerChat();
        }
        setSendModel(false)
     }

    return(
        <>
         <div className="row px-4 pb-4 pt-2">
         <Title back={true} />
         <div className="bg-white round border">
         <div className="row justify-content-center">
         <div className="col-lg-9">
         <div class="table-responsive mt-3 table-well border rounded">
         <table class="table table-hover table-striped mb-0">
         <tbody>
         <tr>
         <td class="text-end" width="30%">Provider: <b>{info['providername']}</b></td>
         <td class="text-start" width="70%">
         {info['coursetype'] === 'single' ? <>Course: <b>{info['coursename']}</b><br/></>
         : <>Package: <b>{info['packagename']}</b><br/></>}
         <small>Intake: <b>{info['intake']}</b></small><br/>
         <small>Status: <b>{info['status']}</b></small>
         </td>
         </tr>
         </tbody></table>
         </div>
         </div>
         </div>
         <div className='row p-2 px-5 bg-white'>
         <div className="container row m-auto px-xxl-2">

         <div className="col-lg-3 col-12 col-md-5 col-xxl-2 form-bg px-2 bg-white area py-3 ">
            <ul className="nav nav-tabs form-tabs d-flex flex-column res-size-14 border-none" id="myTabs">
            <li className="nav-item w-100">
                <button className={`nav-link custom-nav-link w-100 ${activeTab === 'client' ? 'active' : ''}`}
                onClick={() => handleTabClick('client')} >
                <FaHome /> Client/Course Details </button>
            </li>
            <li className="nav-item w-100">
                <button className={`nav-link custom-nav-link w-100 ${activeTab === 'notes' ? 'active' : ''}`}
                onClick={() => handleTabClick('notes')} > 
                <FaLandmark /> Notes/Status </button>
            </li>
        
            <li className="nav-item w-100">
                <button className={`nav-link custom-nav-link w-100 ${activeTab === 'document' ? 'active' : ''}`}
                onClick={() => handleTabClick('document')} >
                <FaUpload /> Attachments </button>
            </li>
            <li className="nav-item w-100">
                <button className={`nav-link custom-nav-link w-100 ${activeTab === 'email' ? 'active' : ''}`}
                onClick={() => handleTabClick('email')} >
                <FaInbox /> Emails </button>
            </li>
            </ul>
            <div className="mt-2">
            <button className="btn btn-success px-4 mx-2 mt-2" onClick={() => setShowNote(true)}>Add Notes</button>
            <br/>
            <button className="btn btn-secondary px-4 mx-2 mt-2" onClick={() => setSendModel(true)}>Send Email</button></div>
         </div>

         <div className="col-lg-9 col-12 col-md-7 col-xxl-10">
         <div className="tab-content mx-2">
          {/* Client Section */}
         <div className={`tab-pane area  ${activeTab === 'client' ? 'show active' : 'hide'}`} id="client">
         <div className="row bg-white justify-content-center m-3">
         <div className="table-responsive p-0">
         <table className="table table-bordered-bottom table-hover table-striped mb-0">
         <tbody>
         <tr>
         <td>Client ID<br/> <strong>{appid}</strong></td>
         <td>Client Passport No.<br/> <strong>{detail['clientpassport']}</strong></td>
         </tr>
         <tr>
         <td>Client Name<br/> <strong>{detail['clientname']}</strong></td>
         <td>Client E-Mail<br/><strong>{detail['clientemail']}</strong></td>
         </tr>
         <tr>
         <td>Client Phone No.<br/> <strong>{detail['clientmobile']}</strong></td>
         <td>Client Country<br/><strong>{detail['clientcountry']}</strong></td>
         </tr>
         <tr>
         {detail['coursetype'] === 'single' ? 
         <td>Course Duration<br/> <strong>{detail['duration']}</strong></td> :''}
         {detail['coursetype'] === 'single' ? 
         <td>Course Fee<br/> <strong>{detail['tution_fee']}</strong></td>:''}
         </tr>
         <tr>
         </tr>
         </tbody>
         </table>
         </div>
         </div>
         </div>
         {/* Notes Section */}
         <div className={`tab-pane area  ${activeTab === 'notes' ? 'show active' : 'hide'}`} id="notes">
         <div className="row bg-white justify-content-center m-2">
         {allNotes[0] ?
         <>
         {allNotes.map((value, key) => 
         <>
         <div key={key} className={`card mb-2 ${value.notes && value.notes.includes('Status changed to') ? 'border-green shadow-sm' : 'shadow-sm'}`}>
         <div className="p-1 pl-lg-3">
         <div className="row px-2 py-1">
         <div className="col-9 pt-1">
         <div className="d-flex">
         <h5 className="size-16">{++key}:</h5>
         <h5 className="size-16">Note: </h5>
         <div className="acc-note pl-2" style={{whiteSpace: 'pre-wrap'}} 
         dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(value.notes)}}></div>
         </div>
         </div>
         <div className="col-3">
         <small className="pr-lg-2 pl-lg-3 pr-2 light-grey size-14">
         <Moment date={value['created_at']}  format="D MMM YYYY, hh:mm:ss" withTime />
         </small>
         </div>
         </div>
         </div>
         </div>
         </>
         )} 
         </> : 'no notes yet'
         }
         </div>
         </div>
         {/* Document Section */}
         <div className={`tab-pane area  ${activeTab === 'document' ? 'show active' : 'hide'}`} id="document">
         <div className="row m-4">
            {documentList.map((x, i) => {
                return(
                <>
                <div className="form-group col-lg-4 pl-0">
                <label className="font-serif pt-lg-1 size-15 mb-0 pt-lg-1 mb-1">File Name:*</label>
                <input type="text" name="name" className="form-control acc-form col-lg-11" 
                placeholder="Please Enter" onChange={e => handleInputChange(e, i, 'document')}
                defaultValue={x.name && x.name} />
                </div>

                <div className="form-group col-lg-3 pl-0">
                <label className="font-serif pt-lg-1 size-15 mb-0 pt-lg-1 mb-1">File Type:*</label>
                <select data-live-search="true" name="type" onChange={e => handleInputChange(e, i, 'document')} 
                className="form-select acc-form col-lg-11" defaultValue={x.type && x.type}>
                <option value=''>Please Select</option>
                {eduFileType.map((value)=> 
                <option key={value.value} value={value.value}>{value.title}</option>
                )}
                </select>
                </div>
    
                <div className="form-group col-lg-5 pl-0">
                <label className="font-serif pt-lg-1 size-15 mb-0 pt-lg-1 mb-1">Upload File</label>
                <input type="file" onChange={(e) => handleInputChange(e, i, 'document')} className="form-control acc-form w-lg-90" placeholder="Please Enter"/>
                </div>

                <div className="d-flex">
                {documentList.length !== 1 && 
                <button className="btn btn-danger mr-2"  onClick={() => handleRemoveClick('document', i)}>Remove</button>}
                {documentList.length - 1 === i &&
                <button className="btn btn-primary" onClick={() => handleAddClick('document')}>Add More</button>}
                </div>
                </>
                )
            })} 
        <div className='row my-4 px-1'>  
            <button type="submit" className="btn btn-success ml-2 pl-3 pr-3">Update</button>
        </div>
         </div>
         </div>
           {/* Email Section */}
         <div className={`tab-pane area  ${activeTab === 'email' ? 'show active' : 'hide'}`} id="email">
         <div className="row m-2 rounded">
            {emailList.map((v, k) => 
            <div className="card mb-2" key={k}>
            <div className="row py-1 py-3" style={{background:'beige'}}>
            {v.conversation.map((value, index) => 
            <div className="row" key={index}>
            <div className="col-1 text-center pl-3 pt-2">
            <img src={value.sender_type === 'partner' ? userlogo1 : userlogo} alt="User" className="img-fluid rounded-circle" style={{ width: '30px', height: '30px', objectFit: 'cover' }} />
            </div>
            <div className="col-8">
            <b className="capital size-16">{value.sender} {value.sender_type === 'partner' ? '(you)' : ''}</b>
            <br/>
            <p className="size-16">{value.body}</p>
            </div>
            <div className='col-3 size-14 '>
            {moment(value.created_at).format('MMM D, YYYY, h:mm A')} </div>
            </div>
            )}
            {showReply === true &&
            <div className="row my-2">
              <div className="col-1 text-center">
             <img src={userlogo1} alt="User" className="img-fluid rounded-circle" style={{ width: '40px', height: '40px', objectFit: 'cover' }} />
             </div>
              <div className="col-10">
              <b className="capital">Aptitude Migration</b>
              <br/>
              <textarea className="textarea form-control bg-white mt-2" rows="6" cols="60" placeholder="Reply..." onChange={(e) => setReply(e.target.value)}></textarea>
              {replyError && <><small className="error">{replyError}</small></>}
              <button className="btn btn-success px-4 my-2" onClick={() => sendReply()}>Send</button>
              </div>

            </div>}
            {showReply == false &&
            <div className="px-2">
                <button className="btn btn-dark py-1 px-4 mx-3" onClick={() => setReplyValues(v.id)} style={{borderRadius:'20px'}}> 
                <FaReply size={16}/> Reply</button>
            </div>}
            </div>
            </div>)} 
         </div>
         </div>

        </div>
        </div>


        </div>
        </div>
         </div>
         </div>
        <Modals
         show={showNote}
         title={'Add Note'}
         data={'Add Note'} 
         type={'category'}
         textarea="true"
         from='edu'
         parentCallback={handleSimpleNoteCallback}
        />
        <SendEmailFromApp
         show={sendModel}
         type={"edu"}
         appid={appid}
         provider={info['providername']}
         client={detail['clientname']}
         course={info['coursetype'] === 'single' ? info['coursename'] : info['packagename']}
         parentCallback={handleEmailCallback}
        />
        </>
    )
}
export default EduAppDetail 