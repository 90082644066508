export const genders = [
    {
        title: 'Male',
        value: '0'
    },
    {
        title: 'Female',
        value: '1'
    }
];

export const migrationType = [
    {
        noicc: 'Notice of Intention to Consider Cancellation (NOICC) of Visa',
        eoi: 'Expression of Interest (EOI)',
        eoi_189: 'Expression of Interest (EOI) - 189',
        eoi_190: 'Expression of Interest (EOI) - 190',
        eoi_491: 'Expression of Interest (EOI) - 491',
        roi: 'Registration of Interest (ROI)',
    }
];
export const gstbycountry = [
    {
        title   : 'AUD',
        value   : 10,
    },
    {
        title   : 'USD',
        value   : 18,
    },
    {
        title   : 'PKR',
        value   : 17,
    },
    {
        title   : 'CAD',
        value   : 5,
    },
    {
        title   : 'MYR',
        value   : 10,
    },
    {
        title   : 'GBP',
        value   : 20,
    },
    {
        title   : 'SGD',
        value   : 7,
    },
]
export const countries_code = [
    {
        countryCode: "+213" ,
        country:  'Algeria (+213)',
        value:'Algeria',
        IsoCode: "DZ"
    },
    {
        countryCode: "+376" ,
        country: 'Andorra (+376)',
        value:'Andorra',
        IsoCode: "AD"
    },
    {
        countryCode: "+244" ,
        country: 'Angola (+244)',
        value:'Angola',
        IsoCode: "AO"
    },
    {    
        countryCode: "+1264" ,
        country: 'Anguilla (+1264)',
        value: 'Anguilla',
        IsoCode: "AI"
    },
    {
        countryCode: "+1268" ,
        country: 'Antigua (+1268)',
        value: 'Antigua',
        IsoCode: "AG"
    },
    {
        countryCode: "+54" ,
        country: 'Argentina (+54)',
        value: 'Argentina',
        IsoCode: "AR"
    },
    {
        countryCode: "+374" ,
        country: 'Armenia (+374)',
        value: 'Armenia',
        IsoCode: "AM"
    },
    {
        countryCode: "+297" ,
        country: 'Aruba (+297)',
        value: 'Aruba',
        IsoCode: "AW"
    },
    {
        countryCode: "+61" ,
        country: 'Australia (+61)',
        value:'Australia',
        IsoCode: "AU"
    },
    {
        countryCode: "+43" ,
        country: 'Austria (+43)',
        value: 'Austria',
        IsoCode: "AT"
    },
    {
        countryCode: "+994" ,
        country: 'Azerbaijan (+994)',
        value: 'Azerbaijan',
        IsoCode: "AZ"
    },
    {
        countryCode: "+1242" ,
        country: 'Bahamas (+1242)',
        value: 'Bahamas',
        IsoCode: "BS"
    },
    {
        countryCode: "+973" ,
        country: 'Bahrain (+973)',
        value: 'Bahrain',
        IsoCode: "BH"
    },
    {
        countryCode: "+880" ,
        country: 'Bangladesh (+880)',
        value: 'Bangladesh',
        IsoCode: "BD"
    },
    {
        countryCode: "+1246" ,
        country: 'Barbados (+1246)',
        value: 'Barbados',
        IsoCode: "BB"
    },
    {
        countryCode: "+375" ,
        country: 'Belarus (+375)',
        value:'Belarus',
        IsoCode: "BY"
    },
    {
        countryCode: "+32" ,
        country: 'Belgium (+32)',
        value:'Belgium',
        IsoCode: "BE"
    },
    {
        countryCode: "+501" ,
        country: 'Belize (+501)',
        value:'Belize',
        IsoCode: "BZ"
    },
    {
        countryCode: "+229" ,
        country: 'Benin (+229)',
        value:'Benin',
        IsoCode: "BJ"
    },
    {
        countryCode: "+1441" ,
        country: 'Bermuda (+1441)',
        value:'Bermuda',
        IsoCode: "BM"
    },
    {
        countryCode: "+975" ,
        country: 'Bhutan (+975)',
        value:'Bhutan',
        IsoCode: "BT"
    },
    {
        countryCode: "+591" ,
        country: 'Bolivia (+591)',
        value:'Bolivia',
        IsoCode: "BO"
    },
    {
        countryCode: "+387" ,
        country: 'Bosnia Herzegovina (+387)',
        value:'Bosnia Herzegovina',
        IsoCode: "BA"
    },
    {
        countryCode: "+267" ,
        country: 'Botswana (+267)',
        value:'Botswana',
        IsoCode: "BW"
    },
    {
        countryCode: "+55" ,
        country: 'Brazil (+55)',
        value:'Brazil',
        IsoCode: "BR"
    },
    {
        countryCode: "+673" ,
        country: 'Brunei (+673)',
        value:'Brunei',
        IsoCode: "BN"
    },
    {
        countryCode: "+359" ,
        country: 'Bulgaria (+359)',
        value:'Bulgaria',
        IsoCode: "BF"
    },
    {
        countryCode: "+226" ,
        country: 'Burkina Faso (+226)',
        value:'Burkina Faso',
        IsoCode: "BF"
    },
    {
        countryCode: "+257" ,
        country: 'Burundi (+257)',
        value:'Burundi',
        IsoCode: "BI"
    },
    {
        countryCode: "+855" ,
        country: 'Cambodia (+855)',
        value:'Cambodia',
        IsoCode: "KH"
    },
    {
        countryCode: "+237" ,
        country: 'Cameroon (+237)',
        value:'Cameroon',
        IsoCode: "CM"
    },
    {
        countryCode: "+1" ,
        country: 'Canada (+1)',
        value: 'Canada',
        IsoCode: "CA"
    },
    {
        countryCode: "+238" ,
        country: 'Cape Verde Islands (+238)',
        value: 'Cape Verde Islands',
        IsoCode: "CV"
    },  {
        countryCode: "+1345" ,
        country: 'Cayman Islands (+1345)',
        value:'Cayman Islands',
        IsoCode: "KY"
    },
    {
        countryCode: "+236" ,
        country: 'Central African Republic (+236)',
        value:'Central African Republic',
        IsoCode: "CF"
    },
    {
        countryCode: "+56" ,
        country: 'Chile (+56)',
        value:'Chile',
        IsoCode: "CL"
    },
    {
        countryCode: "+86" ,
        country: 'China (+86)',
        value:'China',
        IsoCode: "CN"
    },
    {
        countryCode: "+57" ,
        country: 'Colombia (+57)',
        value:'Colombia',
        IsoCode: "CO"
    },
    {
        countryCode: "+269" ,
        country: 'Comoros (+269)',
        value:'Comoros',
        IsoCode: "KM"
    },
    {
        countryCode: "+242" ,
        country: 'Congo (+242)',
        value:'Congo',
        IsoCode: "CG"
    },
    {
        countryCode: "+682" ,
        country: 'Cook Islands (+682)',
        value:'Cook Islands',
        IsoCode: "CK"
    },
    {
        countryCode: "+506" ,
        country: 'Costa Rica (+506)',
        value:'Costa Rica',
        IsoCode: "CR"
    },
    {
        countryCode: "+385" ,
        country: 'Croatia (+385)',
        value:'Croatia',
        IsoCode: "HR"
    },
    {
        countryCode: "+53" ,
        country: 'Cuba (+53)',
        value:'Cuba',
        IsoCode: "CU"
    },
    {
        countryCode: "+90392" ,
        country: 'Cyprus North (+90392)',
        value:'Cyprus North',
        IsoCode: "CY"
    },
    {
        countryCode: "+357" ,
        country: 'Cyprus South (+357)',
        value:'Cyprus South',
        IsoCode: "CY"
    },
    {
        countryCode: "+42" ,
        country: 'Czech Republic (+42)',
        value:'Czech Republic',
        IsoCode: "CZ"
    },
  {
        countryCode: "+45" ,
        country: 'Denmark (+45)',
        value:'Denmark',
        IsoCode: "DK"
    },
    {
        countryCode: "+253" ,
        country: 'Djibouti (+253)',
        value:'Djibouti',
        IsoCode: "DJ"
    },
    {
        countryCode: "+1809" ,
        country: 'Dominica (+1809)',
        value:'Dominica',
        IsoCode: "DM"
    },
    {
        countryCode: "+1809" ,
        country: 'Dominican Republic (+1809)',
        value:'Dominican Republic',
        IsoCode: "DO"
    },
    {
        countryCode: "+593" ,
        country: 'Ecuador (+593)',
        value:'Ecuador',
        IsoCode: "EC"
    },
    {
        countryCode: "+20" ,
        country: 'Egypt (+20)',
        value:'Egypt',
        IsoCode: "EG"
    },
    {
        countryCode: "+503" ,
        country: 'El Salvador (+503)',
        value:'El Salvador',
        IsoCode: "SV"
    },
    {
        countryCode: "+240" ,
        country: 'Equatorial Guinea (+240)',
        value:'Equatorial Guinea',
        IsoCode: "GQ"
    },
    {
        countryCode: "+291" ,
        country: 'Eritrea (+291)',
        value:'Eritrea',
        IsoCode: "ER"
    },
    {
        countryCode: "+372" ,
        country: 'Estonia (+372)',
        value:'Estonia',
        IsoCode: "EE"
    },
    {
        countryCode: "+251" ,
        country: 'Ethiopia (+251)',
        value:'Ethiopia',
        IsoCode: "ET"
    },
    {
        countryCode: "+500" ,
        country: 'Falkland Islands (+500)',
        value:'Falkland Islands',
        IsoCode: "FK"
    },
    {
        countryCode: "+298" ,
        country: 'Faroe Islands (+298)',
        value:'Faroe Islands',
        IsoCode: "FO "
    },
    {
        countryCode: "679" ,
        country: 'Fiji (679)',
        value:'Fiji',
        IsoCode: "FJ"
    },
    {
        countryCode: "+358" ,
        country: 'Finland (+358)',
        value:'Finland',
        IsoCode: "FI"
    },
    {
        countryCode: "+33" ,
        country: 'France (+33)',
        value:'France',
        IsoCode: "FR"
    },
    {
        countryCode: "+594" ,
        country: 'French Guiana (+594)',
        value:'French Guiana',
        IsoCode: "GF"
    },
    {
        countryCode: "+689" ,
        country: 'French Polynesia (+689)',
        value:'French Polynesia',
        IsoCode: "PF"
    },
    {
        countryCode: "+241" ,
        country: 'Gabon (+241)',
        value:'Gabon',
        IsoCode: "GA"
    },
    {
        countryCode: "+220" ,
        country: ' Gambia (+220)',
        value:'Gambia',
        IsoCode: "GM"
    },
    {
        countryCode: "+7880" ,
        country: 'Georgia (+7880)',
        value:'Georgia',
        IsoCode: "GE"
    },
    {
        countryCode: "+49" ,
        country: 'Germany (+49)',
        value:'Germany',
        IsoCode: "DE"
    },
    {
        countryCode: "+233" ,
        country: 'Ghana (+233)',
        value:'Ghana',
        IsoCode: "GH"
    },
    {
        countryCode: "+350" ,
        country: 'Gibraltar (+350)',
        value:'Gibraltar',
        IsoCode: "GI"
    },
    {
        countryCode: "+30" ,
        country: 'Greece (+30)',
        value:'Greece',
        IsoCode: "GR"
    },
  {
        countryCode: "+299" ,
        country: 'Greenland (+299)',
        value:'Greenland',
        IsoCode: "GL"
    },
    {
        countryCode: "+1473" ,
        country: 'Grenada (+1473)',
        value:'Grenada',
        IsoCode: "GD"
    },
    {
        countryCode: "+590" ,
        country: 'Guadeloupe (+590)',
        value:'Guadeloupe',
        IsoCode: "GP"
    },
    {
        countryCode: "+671" ,
        country: 'Guam (+671)',
        value:'Guam',
        IsoCode: "GU"
    },
    {
        countryCode: "+502" ,
        country: 'Guatemala (+502)',
        value:'Guatemala',
        IsoCode: "GT"
    },
    {
        countryCode: "+224" ,
        country: 'Guinea (+224)',
        value:'Guinea',
        IsoCode: "GN"
    },
    {
        countryCode: "+245" ,
        country: 'Guinea - Bissau (+245)',
        value:'Guinea - Bissau',
        IsoCode: "GW"
    },
    {
        countryCode: "+592" ,
        country: 'Guyana (+592)',
        value:'Guyana',
        IsoCode: "GY"
    }, {
        countryCode: "+509" ,
        country: 'Haiti (+509)',
        value:'Haiti',
        IsoCode: "HT"
    },
    {
        countryCode: "+504" ,
        country: 'Honduras (+504)',
        value:'Honduras',
        IsoCode: "HN"
    }, {
        countryCode: "+852" ,
        country: 'Hong Kong (+852)',
        value:'Hong Kong',
        IsoCode: "HK"
    },
    {
        countryCode: "+36" ,
        country: 'Hungary (+36)',
        value:'Hungary',
        IsoCode: "HU"
    }, {
        countryCode: "+354" ,
        country: 'Iceland (+354)',
        value:'Iceland',
        IsoCode: "IS"
    },
    {
        countryCode: "+91" ,
        country: 'India (+91) ',
        value:'India',
        IsoCode: "IN"
    }, {
        countryCode: "+62" ,
        country: 'Indonesia (+62)',
        value:'Indonesia',
        IsoCode: "ID"
    },
    {
        countryCode: "+98" ,
        country: 'Iran (+98)',
        value:'Iran',
        IsoCode: "IR"
    }, {
        countryCode: "+964" ,
        country: 'Iraq (+964)',
        value:'Iraq',
        IsoCode: "IQ"
    },
    {
        countryCode: "+353" ,
        country: 'Ireland (+353)',
        value:'Ireland',
        IsoCode: "IE"
    }, {
        countryCode: "+972" ,
        country: 'Israel (+972)',
        value:'Israel',
        IsoCode: "IL"
    },
    {
        countryCode: "+39" ,
        country: 'Italy (+39)',
        value:'Italy',
        IsoCode: "IT"
    }, {
        countryCode: "+1876" ,
        country: 'Jamaica (+1876)',
        value:'Jamaica',
        IsoCode: "JM"
    },
    {
        countryCode: "+81" ,
        country: 'Japan (+81)',
        value:'Japan',
        IsoCode: "JP"
    }, {
        countryCode: "+962" ,
        country: 'Jordan (+962)',
        value:'Jordan',
        IsoCode: "JO"
    },
    {
        countryCode: "+7" ,
        country: 'Kazakhstan (+7)',
        value:'Kazakhstan',
        IsoCode: "KZ"
    }, {
        countryCode: "+254" ,
        country: 'Kenya (+254)',
        value:'Kenya',
        IsoCode: "KE"
    },
    {
        countryCode: "+686" ,
        country: 'Kiribati (+686)',
        value:'Kiribati',
        IsoCode: "KI"
    }, {
        countryCode: "+850" ,
        country: 'Korea North (+850)',
        value:'Korea North',
        IsoCode: "KP"
    },
    {
        countryCode: "+82" ,
        country: 'Korea South (+82)',
        value:'Korea South',
        IsoCode: "KR"
    }, {
        countryCode: "+965" ,
        country: 'Kuwait (+965)',
        value:'Kuwait',
        IsoCode: "KW"
    },
    {
        countryCode: "+996" ,
        country: 'Kyrgyzstan (+996)',
        value:'Kyrgyzstan',
        IsoCode: "KG"
    }, {
        countryCode: "+856" ,
        country: 'Laos (+856)',
        value:'Laos',
        IsoCode: "LA"
    },
    {
        countryCode: "+371" ,
        country: 'Latvia (+371)',
        value:'Latvia',
        IsoCode: "LV"
    }, {
        countryCode: "+961" ,
        country: 'Lebanon (+961)',
        value:'Lebanon',
        IsoCode: "LB"
    },
    {
        countryCode: "+266" ,
        country: 'Lesotho (+266)',
        value:'Lesotho',
        IsoCode: "LS"
    }, {
        countryCode: "+231" ,
        country: 'Liberia (+231)',
        value:'Liberia',
        IsoCode: "LR"
    },
    {
        countryCode: "+218" ,
        country: 'Libya (+218)',
        value:'Libya',
        IsoCode: "LY"
    }, {
        countryCode: "+417" ,
        country: 'Liechtenstein (+417)',
        value:'Liechtenstein',
        IsoCode: "LI"
    },
    {
        countryCode: "+370" ,
        country: 'Lithuania (+370)',
        value:'Lithuania',
        IsoCode: "LT"
    }, {
        countryCode: "+352" ,
        country: 'Luxembourg (+352)',
        value:'Luxembourg',
        IsoCode: "LU"
    },
    {
        countryCode: "+853" ,
        country: 'Macao (+853)',
        value:'Macao',
        IsoCode: "MP"
    }, {
        countryCode: "+389" ,
        country: 'Macedonia (+389)',
        value:'Macedonia',
        IsoCode: "MK"
    },
    {
        countryCode: "+261" ,
        country: 'Madagascar (+261)',
        value:'Madagascar',
        IsoCode: "MG"
    }, {
        countryCode: "+265" ,
        country: 'Malawi (+265) ',
        value:'Malawi',
        IsoCode: "MW"
    },
    {
        countryCode: "+60" ,
        country: 'Malaysia (+60)',
        value:'Malaysia',
        IsoCode: "MY"
    }, {
        countryCode: "+960" ,
        country: 'Maldives (+960)',
        value:'Maldives',
        IsoCode: "MV"
    },
    {
        countryCode: "+223" ,
        country: 'Mali (+223)',
        value:'Mali',
        IsoCode: "ML"
    }, {
        countryCode: "+356" ,
        country: 'Malta (+356)',
        value:'Malta',
        IsoCode: "MT"
    },
    {
        countryCode: "+692" ,
        country: 'Marshall Islands (+692)',
        value:'Marshall Islands',
        IsoCode: "MH"
    }, {
        countryCode: "+596" ,
        country: 'Martinique (+596)',
        value:'Martinique',
        IsoCode: "MQ"
    },
    {
        countryCode: "+222" ,
        country: 'Mauritania (+222)',
        value:'Mauritania',
        IsoCode: "MR"
    }, 
    {
        countryCode: "+269" ,
        country: 'Mayotte (+269)',
        value:'Mayotte',
        IsoCode: "yt"
    },
    {
        countryCode: "+52" ,
        country: 'Mexico (+52)',
        value:'Mexico',
        IsoCode: "mx"
    },
    {
        countryCode: "+691" ,
        country: 'Micronesia (+691)',
        value:'Micronesia',
        IsoCode: "FM"
    },
    {
        countryCode: "+373" ,
        country: 'Moldova (+373)',
        value:'Moldova',
        IsoCode: "MD"
    },
    {
        countryCode: "+377)" ,
        country: 'Monaco (+377)',
        value:'Monaco',
        IsoCode: "MC"
    },
    {
        countryCode: "+976" ,
        country: 'Mongolia (+976)',
        value:'Mongolia',
        IsoCode: "MN"
    },
    {
        countryCode: "+1664" ,
        country: 'Montserrat (+1664)',
        value:'Montserrat',
        IsoCode: "MS"
    },
    {
        countryCode: "+212" ,
        country: 'Morocco (+212)',
        value:'Morocco',
        IsoCode: "MA"
    },
    {
        countryCode: "+258" ,
        country: 'Mozambique (+258)',
        value:'Mozambique',
        IsoCode: "MZ"
    },
    {
        countryCode: "+95" ,
        country: 'Myanmar (+95)',
        value:'Myanmar',
        IsoCode: "MN"
    },
    {
        countryCode: "+264" ,
        country: 'Namibia (+264)',
        value:'Namibia',
        IsoCode: "NA"
    },
    {
        countryCode: "+674" ,
        country: 'Nauru (+674)',
        value:'Nauru',
        IsoCode: "NR"
    },
    {
        countryCode: "+977" ,
        country: 'Nepal (+977)',
        value:'Nepal',
        IsoCode: "NP"
    },
    {
        countryCode: "+31" ,
        country: 'Netherlands (+31)',
        value:'Netherlands',
        IsoCode: "NL"
    },
    {
        countryCode: "+687" ,
        country: 'New Caledonia (+687)',
        value:'New Caledonia',
        IsoCode: "NC"
    },
    {
        countryCode: "+64" ,
        country: 'New Zealand (+64)',
        value:'New Zealand',
        IsoCode: "NZ"
    },
    {
        countryCode: " " ,
        country: 'Nicaragua (+505)',
        value:'Nicaragua',
        IsoCode: "NI"
    },
    {
        countryCode: " " ,
        country: 'Niger (+227)',
        value:'Niger',
        IsoCode: "NE"
    },
    {
        countryCode: " " ,
        country: 'Nigeria (+234)',
        value:'Nigeria',
        IsoCode: "NG"
    },
    {
        countryCode: " " ,
        country: 'Niue (+683',
        value:'Niue',
        IsoCode: "NU"
    },
    {
        countryCode: " " ,
        country: 'Norfolk Islands (+672)',
        value:'Norfolk Islands',
        IsoCode: "NF"
    },
    {
        countryCode: " " ,
        country: 'Northern Marianas (+670',
        value:'Northern Marianas',
        IsoCode: "NP"
    },
    {
        countryCode: " " ,
        country: 'Norway (+47)',
        value:'Norway',
        IsoCode: "NO"
    },
    {
        countryCode: " " ,
        country: 'Oman (+968)',
        value:'Oman',
        IsoCode: "OM"
    },
    {
        countryCode: " " ,
        country: 'Pakistan (+92)',
        value:'Pakistan',
        IsoCode: "PK"
    },
    {
        countryCode: " " ,
        country: 'Palau (+680)',
        value:'Palau',
        IsoCode: "PW"
    },
    {
        countryCode: " " ,
        country: 'Papua New Guinea (+675)',
        value:'Papua New Guinea',
        IsoCode: "PG"
    },
    {
        countryCode: " " ,
        country: 'Paraguay (+595)',
        value:'Paraguay',
        IsoCode: "PY"
    },
    {
        countryCode: " " ,
        country: 'Peru (+51)',
        value:'Peru',
        IsoCode: "PE"
    },
    {
        countryCode: " " ,
        country: 'Philippines (+63)',
        value:'Philippines',
        IsoCode: "PH"
    },
    {
        countryCode: " " ,
        country: 'Poland (+48)',
        value:'Poland',
        IsoCode: "PL"
    },
    {
        countryCode: " " ,
        country: 'Portugal (+351)',
        value:'Portugal',
        IsoCode: "PT"
    },
    {
        countryCode: " " ,
        country: 'Puerto Rico (+1787)',
        value:'Puerto Rico',
        IsoCode: "PR"
    },
    {
        countryCode: " " ,
        country: 'Qatar (+974)',
        value:'Qatar',
        IsoCode: "QA"
    },
    {
        countryCode: " " ,
        country: 'Reunion (+262)',
        value:'Reunion',
        IsoCode: "RE"
    },
    {
        countryCode: " " ,
        country: 'Romania (+40)',
        value:'Romania',
        IsoCode: "RO"
    },
    {
        countryCode: " " ,
        country: 'Russia (+7)',
        value:'Russia',
        IsoCode: "RU"
    },
    {
        countryCode: " " ,
        country: 'Rwanda (+250)',
        value:'Rwanda',
        IsoCode: "RW"
    },
    {
        countryCode: " " ,
        country: 'San Marino (+378',
        value:'San Marino',
        IsoCode: "SM"
    },
    {
        countryCode: " " ,
        country: 'Principe (+239)',
        value:'Principe',
        IsoCode: "ST"
    },
    {
        countryCode: " " ,
        country: 'Saudi Arabia (+966)',
        value:'Saudi Arabia',
        IsoCode: "SA"
    },
    {
        countryCode: " " ,
        country: 'Senegal (+221)',
        value:'Senegal',
        IsoCode: "SN"
    },
    {
        countryCode: " " ,
        country: 'Serbia (+381)',
        value:'Serbia',
        IsoCode: "CS"
    },
    {
        countryCode: " " ,
        country: 'Seychelles (+248)',
        value:'Seychelles',
        IsoCode: "SC"
    },
    {
        countryCode: " " ,
        country: 'Sierra Leone (+232)',
        value:'Sierra Leone',
        IsoCode: "SL"
    },
    {
        countryCode: " " ,
        country: 'Singapore (+65)',
        value:'Singapore',
        IsoCode: "SG"
    },
    {
        countryCode: " " ,
        country: 'Slovak Republic (+421)',
        value:'Slovak Republic',
        IsoCode: "SK"
    },
    {
        countryCode: " " ,
        country: 'Slovenia (+386',
        value:'Slovenia',
        IsoCode: "SI"
    },
    {
        countryCode: " " ,
        country: 'Solomon Islands (+677',
        value:'Solomon Islands',
        IsoCode: "SB"
    },
    {
        countryCode: " " ,
        country: 'Somalia (+252)',
        value:'Somalia',
        IsoCode: "SO"
    },
    {
        countryCode: " " ,
        country: 'South Africa (+27)',
        value:'South Africa',
        IsoCode: "ZA"
    },
    {
        countryCode: " " ,
        country: 'Spain (+34)',
        value:'Spain',
        IsoCode: "ES"
    },
    {
        countryCode: " " ,
        country: 'Sri Lanka (+94)',
        value:'Sri Lanka',
        IsoCode: "LK"
    },
    {
        countryCode: " " ,
        country: 'St. Helena (+290)',
        value:'St. Helena',
        IsoCode: "SH"
    },
    {
        countryCode: " " ,
        country: 'St. Kitts (+1869)',
        value:'St. Kitts',
        IsoCode: "KN"
    },
    {
        countryCode: " " ,
        country: 'St. Lucia (+1758)',
        value:'St. Lucia',
        IsoCode: "SC"
    },
    {
        countryCode: " " ,
        country: 'Sudan (+249)',
        value:'Sudan',
        IsoCode: "SD"
    },
    {
        countryCode: " " ,
        country: 'Suriname (+597)',
        value:'Suriname',
        IsoCode: "SR"
    },
    {
        countryCode: " " ,
        country: 'Swaziland (+268)',
        value:'Swaziland',
        IsoCode: "SZ"
    },
    {
        countryCode: " " ,
        country: 'Sweden (+46)',
        value:'Sweden',
        IsoCode: "SE"
    },
    {
        countryCode: " " ,
        country: 'Switzerland (+41)',
        value:'Switzerland',
        IsoCode: "CH"
    },
    {
        countryCode: " " ,
        country: 'Syria (+963',
        value:'Syria',
        IsoCode: "SI"
    },
    {
        countryCode: " " ,
        country: 'Taiwan (+886)',
        value:'Taiwan',
        IsoCode: "TW"
    },
    {
        countryCode: " " ,
        country: 'Tajikstan (+7)',
        value:'Tajikstan',
        IsoCode: "TJ"
    },
    {
        countryCode: " " ,
        country: 'Thailand (+66)',
        value:'Thailand',
        IsoCode: "TH"
    },
    {
        countryCode: " " ,
        country: 'Togo (+228',
        value:'Togo',
        IsoCode: "TG"
    },
    {
        countryCode: " " ,
        country: 'Tonga (+676)',
        value:'Tonga',
        IsoCode: "TO"
    },
    {
        countryCode: " " ,
        country: 'Trinidad & Tobago (+1868)',
        value:'Trinidad & Tobago',
        IsoCode: "TT"
    },
    {
        countryCode: " " ,
        country: 'Tunisia (+216)',
        value:'Tunisia',
        IsoCode: "TN"
    },
    {
        countryCode: " " ,
        country: 'Turkey (+90)',
        value:'Turkey',
        IsoCode: "TR"
    },
    {
        countryCode: " " ,
        country: 'Turkmenistan (+7)',
        value:'Turkmenistan',
        IsoCode: "TM"
    },
    {
        countryCode: " " ,
        country: 'Turks & Caicos Islands (+1649',
        value:'Turks & Caicos Islands',
        IsoCode: "TC"
    },
    {
        countryCode: " " ,
        country: 'Tuvalu (+688)',
        value:'Tuvalu',
        IsoCode: "TV"
    },
    {
        countryCode: " " ,
        country: 'Uganda (+256)',
        value:'Uganda',
        IsoCode: "UG"
    },
    {
        countryCode: " " ,
        country: 'UK (+44)',
        value: 'UK',
        IsoCode: "GB"
    },
    {
        countryCode: " " ,
        country: 'Ukraine (+380)',
        value:'Ukraine',
        IsoCode: "UA"
    },
    {
        countryCode: " " ,
        country: 'United Arab Emirates (+971)',
        value:'United Arab Emirates',
        IsoCode: "AE"
    },
    {
        countryCode: " " ,
        country: 'Uruguay (+598)',
        value:'Uruguay',
        IsoCode: "UY"
    },
    {
        countryCode: " " ,
        country: 'USA (+1)',
        value:'USA',
        IsoCode: "US"
    },
    {
        countryCode: " " ,
        country: 'Uzbekistan (+7)',
        value:'Uzbekistan',
        IsoCode: "UZ"
    },
    {
        countryCode: " " ,
        country: 'Vanuatu (+678)',
        value:'Vanuatu',
        IsoCode: "VU"
    },
    {
        countryCode: " " ,
        country: 'Vatican City (+379)',
        value:'Vatican City',
        IsoCode: "VA"
    },
    {
        countryCode: " " ,
        country: 'Venezuela (+58)',
        value:'Venezuela',
        IsoCode: "VE"
    },
    {
        countryCode: " " ,
        country: 'Vietnam (+84)',
        value:'Vietnam',
        IsoCode: "VN"
    },
    {
        countryCode: " " ,
        country: 'Virgin Islands - British (+1284)',
        value:'Virgin Islands - British',
        IsoCode: "VG"
    },
    {
        countryCode: " " ,
        country: 'Virgin Islands - US (+1340)',
        value:'Virgin Islands - US',
        IsoCode: "VI"
    },
    {
        countryCode: " " ,
        country: 'Wallis & Futuna (+681)',
        value:'Wallis & Futuna',
        IsoCode: "WF"
    },
    {
        countryCode: " " ,
        country: 'Yemen (North)(+969)',
        value:'Yemen',
        IsoCode: "YE"
    },
    {
        countryCode: " " ,
        country: 'Yemen (South)(+967)',
        value:'Yemen',
        IsoCode: "YE"
    },
    {
        countryCode: " " ,
        country: 'Zambia (+260)',
        value:'Zambia',
        IsoCode: "ZM"
    },
    {
        countryCode: " " ,
        country: 'Zimbabwe (+263)',
        value:'Zimbabwe',
        IsoCode: "ZW"
    },

];
export const eduFileType = [
    {
        title: 'Ceo file',
        value: 'ceo_file',
    },
    {
        title: 'Acceptance file',
        value: 'acceptance_file',
    },
    {
        title: 'Payment file',
        value: 'payment_file',
    }
];
export const clientFileType = [
    {
        title: 'Ceo file',
        value: 'ceo_file',
    },
    {
        title: 'Acceptance file',
        value: 'acceptance_file',
    },
    {
        title: 'Payment file',
        value: 'payment_file',
    },
    {
        title: 'Withdraw File',
        value: 'withdraw_file',
    },
    {
        title: 'Approval File',
        value: 'approval_file',
    },
    {
        title: 'Refusal File',
        value: 'refusal_file',
    },
    {
        title: 'Others',
        value: 'others',
    },
];

const Constants = { genders, migrationType, gstbycountry, countries_code, eduFileType, clientFileType };

export default Constants;
