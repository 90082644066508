import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import './dashboard.css';
import { get_ApiManager } from '../ApiManager';
import Chart from 'chart.js/auto';
import 'bootstrap/dist/css/bootstrap.min.css';
import { countries_code } from '../utilies/Constants';
import { Bar } from 'react-chartjs-2';

const Dashboard = () => {
  const migrationChartRef = useRef(null);
  const educationChartRef = useRef(null);
  const migrationChartInstance = useRef(null); // For Migration chart instance
  const educationChartInstance = useRef(null); // For Education chart instance
  const [activeTab, setActiveTab] = useState('education');
  const [intakes, setIntakes] = useState([]);
  const [intakeData, setIntakeData] = useState([]);
  const [providers, setProviders] = useState({
    labels: [],
    datasets: []
  });
  const [intakeDataTotal, setIntakeDataTotal] = useState([]);

  const options = {
    scales: {
      x: {
        type: 'linear',
        position: 'bottom',
        min: 0,  // Set the minimum value of the x-axis
        max: 10, // Set the maximum value of the x-axis
        ticks: {
          stepSize: 1,
          callback: function(value) {
            return value;  // Customize X-axis labels
          }
        },
      }
    },
    indexAxis: 'y', 
    borderWidth: 1,// Horizontal bar chart
    elements: {
      bar: {
        borderWidth: 1,
      }
    },
    responsive: true,
    plugins: {
      legend: {
        position: 'right',
      },
      title: {
        display: true,
        text: 'Intake by University'
      }

    }
  };

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem('agent'));
    if (user) {
      getChartData();
    }
  }, [activeTab]); 

   const getChartData = () => {
    let url = `p-getStatuses/${activeTab}`;
    console.log(url)
    get_ApiManager('', url, (res) => {
      console.log('app', res);
      const apiData = res.data.data['statuses'];
      const labels = apiData.map(item => item.name);
      const data = apiData.map(item => item.count);
      const total = data.reduce((acc, count) => acc + count, 0);
  
      // Determine which chart to update based on the active tab
      const myChartRef = activeTab === 'education' 
        ? educationChartRef.current.getContext('2d') 
        : migrationChartRef.current.getContext('2d');
  
      // Destroy the existing chart instance if it exists
      if (activeTab === 'education' && educationChartInstance.current) {
        educationChartInstance.current.destroy();
      } else if (activeTab === 'migration' && migrationChartInstance.current) {
        migrationChartInstance.current.destroy();
      }
  
      // Create the new chart instance
      const newChartInstance = new Chart(myChartRef, {
        type: 'doughnut',
        data: {
          labels: labels, // Use labels from the API
          datasets: [{
            data: data, // Use data from the API
            backgroundColor: ['#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0', '#FF9F40', '#9966FF', '#C9CBCF', '#FF5A5E', '#4BC0C0', '#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0', '#FF9F40', '#9966FF'] // Add more colors if needed
          }]
        }, 
        options: {
          responsive: true,
          maintainAspectRatio: false,
          layout: {
            padding: {
              left: 0, // Adjust the padding to move the chart to the left
            }
          },
          plugins: {
            legend: {
              position: 'right',
              labels: {
                padding: 10,
                font: {
                  size: 14
                }
              }
            },
            tooltip: {
              mode: 'index',
              intersect: false,
            },
          }
        },
        plugins: [{
          id: 'centerText',
          beforeDraw: (chart) => {
            const ctx = chart.ctx;
            ctx.save();
            const centerX = chart.chartArea.left + (chart.chartArea.right - chart.chartArea.left) / 2;
            const centerY = chart.chartArea.top + (chart.chartArea.bottom - chart.chartArea.top) / 2;
            ctx.font = '26px Montserrat sans-serif';
            ctx.textAlign = 'center';
            ctx.textBaseline = 'middle';
            ctx.fillStyle = 'black';
            ctx.fillText(total, centerX, centerY); // Display total in the center
            ctx.restore();
          }
        }]
      });
  
      // Assign the new chart instance to the correct reference
      if (activeTab === 'education') {
        educationChartInstance.current = newChartInstance;
      } else if (activeTab === 'migration') {
        migrationChartInstance.current = newChartInstance;
      }
    }, (err) => {
      console.log(err);
    });
  }
  const getIntakes = (country) => {
    let url = "intakesByCountry"
        let data = country;
        get_ApiManager(data, url,(res) => {
            console.log('intakes',res);
            setIntakes(res.data.data);
        },(err) => {
            console.log(err);
        })
  }
  const getIntakeRecord = (intakeid) => {
    let url = `intakeByCountryRecord/${intakeid}`;
    console.log(url)
    get_ApiManager('', url,(res) => {
        console.log('intakes',res);
        console.log(res.data.data['statuses'])
        setIntakeData(res.data.data['response'])
        setIntakeDataTotal(res.data.data['statuses'])
       
        const dataIntake = res.data.data['dataIntake'] || { labels: [], data: [] };
        const labels = dataIntake.labels.length ? dataIntake.labels.map(item => item) : [];
        const intakeData = dataIntake.data.length ? dataIntake.data.map(item => item) : [];

    setProviders({
      labels: labels,
      datasets: [
        {
          label: 'Intake',
          data: intakeData,
          backgroundColor: ['#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0', '#9966FF', '#FF9F40', '#FFCD56', '#FF9F40'],
  
        }
      ]
    });
    },(err) => {
        console.log(err);
    })
  }
  
  return (
    <div className="row px-4 pb-4 pt-2">
      <div className='row mb-4'>
        <div className="col-md-6 col-sm-12 col-lg-6 px-2 col-xl-3 col-xs-12 padding align-items-stretch">
          <Link className="no-underline" to={'/profile'}>
            <div className='card tile'>
              <div className="tile-heading">Application Submitted Today</div>
              <div className="tile-body">
                <i className="fa-solid fa-user"></i>
                <h2 className="float-end"><span id="app_rec_today"></span>0</h2>
              </div>
            </div>
          </Link>
        </div>

        {/* <div className="col-md-6 col-sm-12 px-2 col-lg-6 col-xl-3 col-xs-12 padding align-items-stretch">
          <Link className="no-underline" to={'/profile'}>
            <div className='card tile'>
              <div className="tile-heading">Webinar</div>
              <div className="tile-body">
                <div className="text-start text-center text-danger">Webinar Not Available</div>
              </div>
            </div>
          </Link>
        </div> */}

        <div className="col-md-6 col-sm-12 px-2 col-lg-6 col-xl-9 px-2 col-xs-12 padding align-items-stretch">
          <div className='card tile'>
            <ul className="nav nav-tabs chart-tabs" id="chartTabs" role="tablist">
              <li className="nav-item">
                <button
                  className={`nav-link charttab ${activeTab === 'migration' ? 'active' : ''}`}
                  id="migration-tab"
                  onClick={() => setActiveTab('migration')} >
                  Visa
                </button>
              </li>
              <li className="nav-item">
                <button
                  className={`nav-link charttab ${activeTab === 'education' ? 'active' : ''}`}
                  id="education-tab"
                  onClick={() => setActiveTab('education')}
                >
                  Admission
                </button>
              </li>
            </ul>
            <div className="tab-content" id="chartTabContent">
              <div className={`tab-pane fade ${activeTab === 'migration' ? 'show active' : ''}`} id="migration" role="tabpanel" aria-labelledby="migration-tab">
                <div className="card" style={{ paddingRight: "2rem" }} id="migrationChartParentContainer">
                  <canvas className="customCanvas" id="migrationChart" ref={migrationChartRef} style={{ padding: '10px' }}></canvas>
                </div>
              </div>
              <div className={`tab-pane fade ${activeTab === 'education' ? 'show active' : ''}`} id="education" role="tabpanel" aria-labelledby="education-tab">
                <div className="card" style={{ paddingRight: "2rem" }} id="educationChartParentContainer">
                  <canvas className="customCanvas" id="educationChart" ref={educationChartRef} style={{ padding: '10px' }}></canvas>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='row px-2'>
      <div className='card tile'>
        <div className="tab-content">
          <div className='row m-3'>
          <div className='col-lg-2'>
          <select className="form-select app-form " onChange={(e) => getIntakes(e.target.value)}>
          <option value="">Select Country</option>
          {[].concat(countries_code).sort((a, b) => a.value > b.value ? 1 : -1).map(value => 
          <option key={value.id} value={value.id}>{value.value}</option> )}
          </select>
          </div>
          <div className='col-lg-2 mx-lg-3'>
          <select className="form-select app-form " onChange={(e) => getIntakeRecord(e.target.value)}>
          <option value="">Select Intake</option>
          {intakes.map((value,index)=> 
            <option key={index} value={value.value}>{value.label}</option> )}
          </select>
          </div>
          </div>
          <div className="table-responsive mt-2">
          <table className="table table-bordered table-hover table-striped">
            <thead className="thead-light">
              <tr>
                <th>Intake</th>
                <th>Recieved</th>
                <th>Lodged</th>
                <th>Conditional/Full Offer</th>
                <th>Discontinued</th>
                <th>Offer Accepted</th>
                <th>COE Recieved</th>
                <th>Refused</th>
                <th>Refund</th>
              </tr>
            </thead>
            <tbody>
            {intakeData[0] ?
            <>
            {intakeData.map((value,index) => (
                <tr key={index}>
                  <td className='text-center'>{value.title}</td>
                  <td className='text-center'>{value.status === 1 ? 1:0}</td>
                  <td className='text-center'>{value.status === 2 ? 1:0}</td>
                  <td className='text-center'>{value.status === 3 ? 1:0}</td>
                  <td className='text-center'>{(value.status === 6 || value.status === 102) ? 1:0}</td>
                  <td className='text-center'>{value.status === 7 ? 1:0}</td>
                  <td className='text-center'>{value.status === 100 ? 1:0}</td>
                  <td className='text-center'>{value.status === 9 ? 1:0}</td>
                  <td className='text-center'>{value.status === 101 ? 1:0}</td>
                  <td className='text-center'>{value.status === 104 ? 1:0}</td>
             </tr>))}
             </>:'no record found'}

              {intakeDataTotal.received ?
              <tr className="">
                <td className='text-right bold'>Total</td>
                  <td className='text-center'>{intakeDataTotal.received}</td>
                  <td className='text-center'>{intakeDataTotal.lodged}</td>
                  <td className='text-center'>{Number(intakeDataTotal.full_offer_received || 0) + Number(intakeDataTotal.conditional_offer_received || 0)}</td>
                  <td className='text-center'>{intakeDataTotal.discontinued}</td>
                  <td className='text-center'>{intakeDataTotal.offer_accepted}</td>
                  <td className='text-center'>{intakeDataTotal.coe_received}</td>
                  <td className='text-center'>{intakeDataTotal.refused}</td>
                  <td className='text-center'>{intakeDataTotal.refund_applied}</td>
              </tr>:''}
            </tbody>
          </table>
        </div>
        </div>
        <div className='row mt-2 px-5'>
      <div className='px-5'>
      {providers.labels.length > 0 && <Bar data={providers} options={options} />}

    </div>
      </div>
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
