
import React, { Component } from 'react';
import { Modal, Button } from "react-bootstrap";
import Multiselect from 'multiselect-react-dropdown';

class Modals extends Component {
    constructor(props) {
        super(props);
        this.state = {
          show: false,
          name:'',
          email:'',
          mobile:'',
          country:'',
        }
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleModal(){
         this.props.parentCallback(this.props.show, false); 
    } 

    handleChange(event) {
        this.setState({
            [event.target.name]: event.target.value
        });
    }      
    
     handleSubmit() {
         this.props.parentCallback( this.props.show, 'edit', this.state.name);
    }
    
  render(){ 
      return(
          <>
            <Modal aria-labelledby="contained-modal-title-vcenter" centered  show={this.props.show} onHide={()=>this.handleModal()}>  
                <Modal.Header closeButton={this.props.endButton == true ? "" : true}><div className="text-center col-11 font-f-sans white">{this.props.title}</div></Modal.Header>  
                <Modal.Body className="ml-2 mr-2">
                {this.props.type == 'edit' ? 
                <form action='' onSubmit={this.handleSubmit}>
                <div className="row">
                <div className="form-group col-6" >
                <label className="profile-label">Name:</label>
                <input name="name" onChange={this.handleChange}  className="card-text form-control acc-form col-11" defaultValue={this.props.data['name']} />
                </div>
                <div className="form-group col-6" >
                <label className="profile-label">Email:</label>
                <input name="email" onChange={this.handleChange}  className="card-text form-control acc-form col-11" defaultValue={this.props.data['email']} />
                </div>
                <div className="form-group col-6" >
                <label className="profile-label ">Mobile:</label>
                <input name="mobile" onChange={this.handleChange}  className="card-text form-control acc-form col-11" defaultValue={this.props.data['mobile']} />
                </div>
                <div className="form-group col-6" >
                <label className="profile-label ">Country:</label>
                <select onChange={this.handleChange} className="form-select acc-form form-select-lg w-lg-90" defaultValue={this.props.data['country']} aria-label="form-select-lg example" name="country" >
                    <option value="Pakistan">Pakistan</option>
                    <option value="Australia">Australia</option>
                    <option value="Other">Other</option>
                </select>
                </div>
                </div>
                </form>
                :''}
                {this.props.type === 'category' ? 
                <form action='' onSubmit={this.handleSubmit}>
                <div className="row">
                <div className="form-group col-12" >

                <label className="profile-label">{this.props.data}</label>
              
                <textarea className="textarea form-control app-form" placeholder="Please Enter" 
                name="name" defaultValue={this.state.name} onChange={this.handleChange} rows="4" cols="60"></textarea>
                </div>
                </div>

                </form>
                : ''}
                </Modal.Body>  
                <Modal.Footer>  
                <Button className="btn btn-danger" onClick={()=>this.handleModal()}>Cancel</Button>
                {this.state.name ? 
                <Button className="btn btn-success" onClick={this.handleSubmit}>Update</Button>  :''}
                </Modal.Footer>  
            </Modal>  
          </>
      )
  }
}
export default Modals