
import React ,{useState,useEffect}from "react"
import { get_ApiManager, post_ApiManager, view_ApiManager } from "../ApiManager"
import { Link } from "react-router-dom";
import { Sorting } from "../GenericComponents/SortTables";
import Paginations from "../GenericComponents/Pagination";
import { FaEdit, FaEye } from 'react-icons/fa';
import ExportOptions from "../GenericComponents/ExportOptions";
import swal from 'sweetalert';
import CreateMigApp from "./createMigApp";
import moment from 'moment';
import EditMigApp from "./editMigApp";

const AllMigrationApps = () => {
    const [perPage, setPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [lastPage, setLastPage] = useState('');
    const [totalItem, setTotalItem] = useState('');
    const [eduIndex, setEduIndex] = useState('');
    const [perItem, setPerItem] = useState('');
    const [order, setOrder] = useState("ASC");
    const [allMigApps, setAllMigApps] = useState([]);
    const [selectedMig, setSelectedMig] = useState([]);
    const [categories, setCategories] = useState([]);
    const [allClients, setAllClients] = useState([]);
    const [showModel, setShowModel] = useState(false);
    const [categoryid, setCategoryId] = useState('');
    const [providers, setProviders] = useState('');
    const [providerid, setProviderId] = useState('');
    const [clientid, setClientId] = useState('');
    const [visaid, setVisaId] = useState('');
    const [visaTypes ,setVisaTypes] = useState([]);
    const [notes, setNotes] = useState('');
    const [editAppData ,setEditAppData] = useState([]);
    const [showEditModel, setShowEditModel] = useState(false);
    const [headers, setHeaders] = useState([ 
        {title: '#' , dataKey: "", align: "text-center", key:1, checked: true, canChange: false},
        {title: 'Client' , dataKey: "clientname", align: "text-center", key:2, checked: true, canChange: false},
        {title: 'Institute', dataKey: "coursename" , align:"text-center", key:3, checked: true, canChange: false},
        {title: 'App Type', dataKey: "providername", align:"text-center", key:4, checked: true, canChange: false},
        {title: 'Status' , dataKey: "status", align:"text-center", key:5, checked: true, canChange: true},
        {title: 'Created At' , dataKey: "created_at", align:"text-center", key:7, checked: true, canChange: true},
        {title: '' , dataKey: "", align:"text-center", key:8, checked: true, canChange: true},
        {title: '' , dataKey: "", align:"text-center", key:9, checked: true, canChange: true},
    ]);

    useEffect( () => {
        getMigApplications();
    }, [currentPage, perPage]);

    const getMigApplications = () => {
        let url = `p-migrationApps`;
        console.log(url, currentPage, perPage);
        view_ApiManager(url, currentPage, perPage,(res) => {
            console.log('mig-apps',res);
            setAllMigApps(res.data.data['data']);
            setPerPage(res.data.data['per_page']);
            setLastPage(res.data.data['last_page']);
            setTotalItem(res.data.data['total']);
            setPerItem(res.data.data['data'].length);
            setEduIndex(currentPage * perPage - perPage + 1);
            // let options = res.data.data['data'].map(function (item) {
            //     return { 
            //       index: item.index,
            //       name: item.clientname,
            //       coursename: item.coursename,
            //       providername: item.providername,
            //       datelodged: item.datelodged,
            //       rfidate: item.rfidate,
            //       status: item.status,
            //       statuschangedate: item.statuschangedate,
            //       created_at: moment(item.created_at).format('YYYY-MM-D'),
            //       intake: item.intake,
            //     };
            //   })
            //   setSelectedEdu(options);
        },(err) => {
            console.log(err);
        });
    }
    const sorting = (col) => {
        const sortResult = Sorting(allMigApps, col, order)
        setOrder(sortResult[0]);
        setAllMigApps(sortResult[1]);
    }
    const handlePaginationCallback = (i, perPage) => {
        if(perPage && perPage != ''){
            setCurrentPage(1);
            setPerPage(perPage); 
        } else { setCurrentPage(i);  }
    }
    const editApp = (appid) => {
        let url = `p-getMigAppInfo/${appid}`;
        get_ApiManager('', url,(res) => {
            console.log('mig-info',res);
            setEditAppData(res.data.data);
            setProviderId(res.data.data['providerid'])
            setCategoryId(res.data.data['categoryid'])
            setVisaId(res.data.data['courseid'])
            getClients();
            getCategories();
            setClientId(res.data.data['clientid']);
            getProviders(res.data.data['categoryid']);
            getVisa(res.data.data['providerid']);
            setNotes(res.data.data['notes']);
            setShowEditModel(true)
        },(err) => {
            console.log(err);
        }) 
    }
    const getCategories = async () => {
        let url = 'migrationCategory';
        let data = '';
        get_ApiManager(data, url,(res) => {
            console.log('mig-category',res);
            setCategories(res.data['data']);
        },(err) => {
            console.log(err);
        })
     }
    const createMig = () => {
        getCategories();
        getClients();
        setShowModel(true)
     }
    const handleModelCallback = (type, value) => {
        if(type === 'edit'){
            createMigApps();
            setShowModel(false)
        } else if(type === 'client'){
            setClientId(value)
        } else if(type === 'category'){
            setProviderId('')
            setCategoryId(value)
            getProviders(value)
        } else if(type === 'provider'){
            setProviderId(value)
            getVisa(value)
        } else if(type === 'visa'){
            setVisaId(value)
        } else if(type === 'note'){
            setNotes(value)
        } else if('close'){
            resetValues();
            setShowModel(false)
        }
     }
    const getProviders = (categoryID) => {
        let url = 'getProvidersByNames';
        let data = categoryID;
        get_ApiManager(data, url,(res) => {
            console.log('providers',res);
            setProviders(res.data['data']);
        },(err) => {
            console.log(err);
        })
     }
    const getVisa = (providerID) => {
        let url = 'getCourseByNames';
        let data = providerID;
        get_ApiManager(data, url,(res) => {
            console.log('visa',res);
            setVisaTypes(res.data['data']);
        },(err) => {
            console.log(err);
        })
     }
    const resetValues = () => {
        setVisaId('');
        setCategoryId('');
        setProviderId('');
        setVisaTypes([]);
        setProviders([]);
        setCategories([]);
        setClientId('');
        setNotes('');
     }
    const createMigApps = () => {
        const data = {
            clientid: clientid,
            categoryid: categoryid,
            providerid : providerid,
            visaid: visaid,
            notes: notes,
        }
        let url = `p-createMigApp`;
        console.log(data);
        post_ApiManager(data, url, (res) => {
            console.log('app-created',res);
            if(res.data.error) swal(" ", res.data.error, "error")
            else {
                swal("Success!", res.data.message, "success"); 
                resetValues();
                getMigApplications();
               }  
        },(err) => {
            console.log(err);
        });
     }
     const getClients = () => {
        let url = `p-allClients`;
        console.log(url);
        get_ApiManager('', url, (res) => {
          console.log('all-clients', res);
          setAllClients(res.data[0]);
        }, (err) => {
          console.log(err);
        });
    }
    const handleEditModelCallback = (type, value, name) =>{
        if(type === 'close'){
            setShowEditModel(false)
            resetValues();
        } else if(type === 'category'){
            setProviderId('')
            setCategoryId(value)
            getProviders(value)
        } else if(type === 'provider'){
            setVisaId('')
            getVisa(value);
        } else if(type === 'provider'){
            setVisaId(value)
        } else if(type === 'notes'){
            setNotes(value);
        } else if(type === 'edit'){
            updateApp(value);
        }
    }
    const updateApp = (appid) => {
        const data = {
            clientid: clientid,
            categoryid: categoryid,
            providerid : providerid,
            visaid: visaid,
            notes: notes,
            appid: appid,
        }
        console.log(data);
        let url = `p-updateMigApp`;
        console.log(data);
        post_ApiManager(data, url, (res) => {
            console.log('app-created',res);
            if(res.data.error) swal(" ", res.data.error, "error")
            else {
                swal("Success!", res.data.message, "success"); 
                resetValues();
                getMigApplications();
                setShowEditModel(false)
               }  
        },(err) => {
            console.log(err);
        });
     }

    return(
        <>
        <div className='row px-4 pb-4 pt-2'>
        <div className='d-flex my-3'>
        <button className='text-left btn btn-main mx-2' onClick={() => createMig()}>Create Application</button>
        </div>

        <div className='mt-2'>
        <table className={`table table-bordered bg-white mb-1`}>
        <thead className="thead-dark">
         <tr>
         {headers.map(head => ( head.checked &&
         <th id={head.dataKey} className={head.align} key={head.key} onClick={() => sorting(head.dataKey)}> {head.title}</th> ))}
         </tr>
         </thead>
         <tbody>
         {allMigApps.map((value, index) => (
            <tr key={value.id}>
            <td className='text-center'>{++index}</td>
            <td className='text-center'>{value.clientname}</td>
            <td className='text-center'>{value.coursename}</td>
            <td className='text-center'>{value.providername}</td>
            <td className='text-center'>{value.status}</td>
            <td className='text-center'>{moment(value.created_at).format('D MMMM YYYY')}</td>
            <td className='text-center'>
            <Link to={`/migration-appplication-detail/${value.id}`} className="icon-link">
            <div className="icon-container"> 
            <FaEye className="eye-icon" style={{color:'grey'}} /> 
            <span className="hover-text">View</span>
            </div> 
            </Link></td>
            <td className='text-center'>
            <div className="icon-container" onClick={() => editApp(value.id)}> 
            <FaEdit className="eye-icon" style={{color:'grey'}} /> 
            <span className="hover-text">Edit</span>
            </div> 
            </td>
            </tr>))}
         </tbody>
         </table>
        </div>
        <Paginations
         currentPage={currentPage}
         total={totalItem} 
         itemsPerPage= {perPage}
         lastPage={lastPage}
         totalItems={totalItem}
         perItem={perItem}
         onPageChange={handlePaginationCallback} 
        />
         <div className="d-flex mb-5">
         <ExportOptions
         pdffilename={'EducationApps.pdf'} 
         csvfilename={'EducationApps.csv'} 
         xlsxfilename={'EducationApps'} 
         header="EducationApps List"
         id="viewclientsdiv3" 
         columns={headers}
         data={selectedMig} />
         </div>

        </div>

        <CreateMigApp
         show={showModel}
         allClients={allClients}
         categories={categories}
         categoryid={categoryid}
         providerid={providerid}
         clientid={clientid}
         providers={providers}
         visaTypes={visaTypes}
         visaid={visaid}
         parentCallback={handleModelCallback}
         />
         
         <EditMigApp
          show={showEditModel}
          allClients={allClients}
          categories={categories}
          categoryid={categoryid}
          providerid={providerid}
          providers={providers}
          data={editAppData}
          visaTypes={visaTypes}
          visaid={visaid}
          notes={notes}
          parentCallback={handleEditModelCallback}
         />

        </>
    )
}
export default AllMigrationApps