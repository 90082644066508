
export const main_url = () => {
    return 'https://api.aptitudemigration.com.au/public';
    // return 'http://127.0.0.1:8000';
}

export const image_url = () => {
    return 'https://api.aptitudemigration.com.au';
    // return 'http://127.0.0.1:8000';
}

export const getUser = () => {
    const userStr = localStorage.getItem("agent");
    if(userStr) return JSON.parse(userStr);
}

export const getToken = () => {
    return  localStorage.getItem("token") || null;
}

export const setUserSession = (token, user) => {
    localStorage.setItem("token" ,token);
    localStorage.setItem("agent_id" ,JSON.stringify(user['id']));
    localStorage.setItem("agent" ,JSON.stringify(user));
}

export const setUserPermissions = (permissions) => {
    localStorage.setItem("permissions" ,JSON.stringify(permissions));
}

export const removeUserSession = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("agent");
    localStorage.removeItem("permissions");
    localStorage.clear()
}