// App.js
import React, { useEffect, useState } from 'react';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter as Router, Routes, Route, Navigate, useLocation, useNavigate } from 'react-router-dom';
import Login from './components/Login/login';
import Dashboard from './components/Dashboard/dashboard';
import PrivateRoute from './components/PrivateRoute';
import './App.css';
import Logout from './components/Logout';
import { io } from 'socket.io-client';
import { get_ApiManager } from './components/ApiManager';
import Header from './components/Header/header';
import { isTokenValid } from './components/utilies/auth'
import Profile from './components/Profile/profile';
import StudentProfile from './components/Student/studentProfile';
import StudentList from './components/Student/studentList';
import AddStudent from './components/Student/addStudent';
import AllEducationApps from './components/Applications/eduApps';
import EduAppDetail from './components/Applications/eduAppDetail';
import AllMigrationApps from './components/Applications/migApps';
import MigAppDetail from './components/Applications/migAppDetail';
import Inbox from './components/Inbox/inbox';
import EmailDetailComponent from './components/Inbox/EmailDetail';

function App() {
  return (
    <Router>
      <AppContent />
    </Router>
  );
}

function AppContent() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [chatNotiication, setChatNotification] = useState(0);
  const navigate = useNavigate();
  const [socket, setSocket] = useState(null);
  const location = useLocation();
  const shouldHideSidebar = location.pathname === '/login' || location.pathname === '/';

  useEffect(() => {
    const checkToken = async () => {
      const valid = await isTokenValid();
      if (!valid) {
        localStorage.removeItem('token');
        navigate('/login');
      } else {
        setIsLoggedIn(true);
      }
    };

    checkToken();
  }, [navigate]);

  useEffect(() => {
    const newSocket = io("https://sockets.aptitudemigration.com.au:3002/");
    setSocket(newSocket);
    console.log(newSocket)
    return () => newSocket.close();
  }, []);

  useEffect(() => {
    getChatNotification();
  },[])

  useEffect(() => {
    socket?.on("getPartnerChatNotification", () => {
      console.log('chat-notification');
      getChatNotification();
    });
  },[socket])

  const getChatNotification = () => {
    let url = 'getPartnerChatNotification';
    get_ApiManager('', url,(res) => {
      console.log('chat-count',res);
      setChatNotification(res.data.data)
    },(err) => {
        console.log(err);
    });
   }

  return (
    <div className="app-container">
      {!shouldHideSidebar && (
        <>
          <Header count={chatNotiication} />
        </>
      )}
      <div className="">
        <Routes>
          <Route path="/login" element={localStorage.getItem('token') ? <Navigate to="/dashboard" /> : <Login />} />
          <Route path="/" element={localStorage.getItem('token') ? <Navigate to="/dashboard" /> : <Login />} />
          <Route path="/logout" element={<Logout />} />
          <Route path="/dashboard" element={<PrivateRoute isLoggedIn={isLoggedIn}> <Dashboard /></PrivateRoute>} />
          <Route path="/my-profile" element={<PrivateRoute isLoggedIn={isLoggedIn}> <Profile /></PrivateRoute>} />
          <Route path="/client-profile/:id" element={<PrivateRoute isLoggedIn={isLoggedIn}> <StudentProfile /></PrivateRoute>} />
          <Route path="/client-list" element={<PrivateRoute isLoggedIn={isLoggedIn}> <StudentList /></PrivateRoute>} />
          <Route path="/create-client" element={<PrivateRoute isLoggedIn={isLoggedIn}> <AddStudent /></PrivateRoute>} />
          <Route path="/education-apps" element={<PrivateRoute isLoggedIn={isLoggedIn}> <AllEducationApps /></PrivateRoute>} />
          <Route path="/education-appplication-detail/:appid" element={<PrivateRoute isLoggedIn={isLoggedIn}> <EduAppDetail socket={socket}/></PrivateRoute>} />
          <Route path="/migration-apps" element={<PrivateRoute isLoggedIn={isLoggedIn}> <AllMigrationApps /></PrivateRoute>} />
          <Route path="/migration-appplication-detail/:appid" element={<PrivateRoute isLoggedIn={isLoggedIn}> <MigAppDetail socket={socket} /></PrivateRoute>} />
          <Route path="/inbox" element={<PrivateRoute isLoggedIn={isLoggedIn}> <Inbox socket={socket} /></PrivateRoute>} />
          <Route path="/email/:id" element={<PrivateRoute isLoggedIn={isLoggedIn}> <EmailDetailComponent socket={socket} /></PrivateRoute>} />
        </Routes>
      </div>
    </div>
  );
}

export default App;
