// utils/auth.js
import axios from 'axios';
import { main_url } from '../utilies/Common';

export const isTokenValid = async () => {
  const token = localStorage.getItem('token');
  if (!token) return false;

  try {
    const response = await axios.post(`${main_url()+'/api/v1/p-check-token'}`, {}, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    console.log('Response from API:', response.data);
    return response.data.status === true;
  } catch (error) {
    console.error('Token validation error:', error);
    return false;
  }
};
