
import React, { Component } from 'react';
import {post_ApiManager } from "../ApiManager";
import { countries_code } from "../utilies/Constants";
import './student.css'
import swal from 'sweetalert';
import Title from '../GenericComponents/Title';
import PhoneInput from 'react-phone-number-input';
import smallSpinner from '../../assets/small.gif';
import { useNavigate } from "react-router-dom";

export const  withNavigation = (Component : Component) => {
    return props => <Component {...props} navigate={useNavigate()} />;
} 

class AddStudent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading:false,
            nameError: '', emailError:'', mobileError:'', phoneError:'', descriptionError:'', countryError: '', genderError: '',
            name:'',
            email:'',
            mobile:'',
            country:'',
            gender:'',
            city:'',
            countryCode: +61,
            defaultCountry:'AU',
        }
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleOptionChange = this.handleOptionChange.bind(this);
    }

    componentDidMount() {
    }
      //set input values
     handleChange(event) {
        this.setState({
            [event.target.name]: event.target.value
        });
    }
    
    handleOptionChange(e) {
        this.setState({
            [e.target.name]: e.target.options[e.target.selectedIndex].text
        });
    }

     handleSubmit(event) {
        event.preventDefault();
        if (this.handleValidation()) {
            this.setState({loading:true})
            const data = {
                name : this.state.name,
                countryCode : this.state.countryCode ? this.state.countryCode: '+61',
                mobile: this.state.mobile,
                gender: this.state.gender,
                email: this.state.email,
                city: this.state.city,
                country: this.state.country,
                description: this.state.description,
            }
            console.log(data);
            let url = 'p-addClient';
            post_ApiManager(data, url, (res) => {
                console.log('client-added' , res);
                this.setState({loading:false})
                if(res.data.error) swal(" ", res.data.error, "error")
                else {
                    swal("Success!", res.data.message , "success"); 
                    this.props.navigate('/client-list')
                };   
            },(err) => {
                console.log(err);
            });
        }
    }
    handleValidation = () => {
        var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
        this.setState({nameError: null, emailError: null, mobileError: null, genderError: null, countryCode: null, cityError: null, descriptionError: null, countryError: null });

        if(!this.state.name){
            window.scrollTo(0,this.scrollRef);
            this.setState({nameError : 'Please enter name'})
            return false }
        else if(!this.state.email){
            window.scrollTo(0,this.scrollRef);
            this.setState({emailError: 'Please enter email'})
            return false }
        else if(this.state.email && !pattern.test(this.state.email)){
            window.scrollTo(0,this.scrollRef);
            this.setState({emailError: 'Please enter correct email'})
            return false }
        else if(!this.state.country){
            this.setState({countryError : 'Please select country'})
            return false }
        else if(!this.state.city){
            this.setState({cityError : 'Please select city'})
            return false }
        else if(!this.state.gender){
            this.setState({genderError : 'Please select gender'})
            return false }
        else if(!this.state.mobile){
            window.scrollTo(0,this.scrollRef);
            this.setState({mobileError : 'Please enter phone'})
            return false }
        else if(!this.state.mobile.length > 13){
            window.scrollTo(0,this.scrollRef);
            this.setState({phoneError : 'invalid phone'})
            return false }
        else if(!this.state.description){
            this.setState({descriptionError : 'Please enter short description'})
            return false }
        else return true;
     }

    render(){
        return(
            <div className='row px-4 pb-4 pt-2'>
             <Title back={true} />
             <div className='row p-4 border px-5 round bg-white'>
             <h5 className='font-monst text-center bold p-3 bg-wheat'>Create Client</h5>
             <div className='row pl-1'>

             <div className='col-lg-4 my-2'>
             <label className="acc-label2 size-18">Full Name</label>
             <input className="card-text form-control acc-form col-lg-11" name="name" onChange={this.handleChange}
              placeholder={'Please enter'} />
               {this.state.nameError && <><small className="error">{this.state.nameError}</small></>}
             </div>

             <div className='col-lg-4 my-2'>
             <label className="acc-label2 size-18">Email</label>
             <input className="card-text form-control acc-form col-lg-11" name="email" onChange={this.handleChange}
              placeholder={'Please enter'} />
               {this.state.emailError && <><small className="error">{this.state.emailError}</small></>}
             </div>

             <div className='col-lg-4 my-2'>
             <label className="acc-label2 size-18">Select Country</label>
             <select className="form-select app-form acc-form" onChange={this.handleOptionChange} aria-label="form-select-lg example" name="country">
             <option selected>Please Select</option>
             {countries_code.map(value => 
             <option value={value.country}>{value.value}</option>
             )}
             </select>
             {this.state.countryError && <><small className="error">{this.state.countryError}</small></>}
             </div>

             <div className="col-lg-4 my-2">
                <div className="form-group">
                <label className="acc-label2 size-18">City</label>
                <input type="text" name="city" onChange={this.handleChange} className="form-control acc-form w-lg-90" placeholder="Please Enter"/>
                {this.state.cityError && <><small className="error">{this.state.cityError}</small></>}
             </div>
             </div>

             <div className="col-lg-4 my-2">
             <div className="form-group">
                <label className="acc-label2 size-18">Select Gender</label>
                <select className="form-select acc-form form-select-lg w-lg-90" aria-label="form-select-lg example" name="gender" onChange={this.handleChange}>
                <option selected>Please Select</option>
                <option value="0">Male</option>
                <option value="1">Female</option>
                <option value="2">Other</option>
                </select>
                {this.state.genderError && <><small className="error">{this.state.genderError}</small></>}
             </div>
             </div>

             <div className="col-lg-4 my-2">
             <div className="form-group">
                <label className="acc-label2 size-18">Mobile</label>
                <div className="col-12 d-flex pl-0">
                <PhoneInput
                international
                defaultCountry={this.state.defaultCountry}
                name='countryCode'
                className="d-flex col-lg-6 pr-0 pl-0"
                onChange={countryCode => this.setState({ countryCode})}
                disabled={(this.state.view === true || this.state.view === "true") ? 'disabled' : ''}
                />  
                <input type="number" name="mobile" onChange={this.handleChange} className="acc-form contact col-6"
                disabled={(this.state.view === true || this.state.view === "true") ? 'disabled' : ''} />
                </div>
                {this.state.mobileError && <><small className="error">{this.state.mobileError}</small></>}
              </div>
             </div>

             <div className="col-lg-7">
                <div className="form-group">
                <label className="acc-label2 size-18">Description</label>
                <textarea className="textarea form-control acc-form" name="description" 
                 placeholder="Please enter" onChange={this.handleChange} rows="4" cols="6"></textarea>
                 {this.state.descriptionError && <><small className="error">{this.state.descriptionError}</small></>}
              </div>
             </div>

             <div className="col-12 my-3 scroll">
                 {/* show loader when date selected */}
                 {this.state.loading ?
                <img src={smallSpinner} width="15" className="pt-2" alt="loading" />:
                <button type="submit" className="btn btn-success col-12" onClick={this.handleSubmit}>Create Client</button>}
             </div>
             </div>

             </div>
            </div>
        )
    }
}
export default withNavigation(AddStudent);