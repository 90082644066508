
import React, { useEffect, useState} from 'react';
import { get_ApiManager, post_ApiManager } from '../ApiManager';
import Title from '../GenericComponents/Title';
import PhoneInput from 'react-phone-number-input';
import swal from 'sweetalert';
import smallSpinner from '../../assets/small.gif';

const Profile = () => {
    const [defaultCountry] = useState('AU');
    const [loading, setLoading] = useState(false);
    const [initialProfileData, setInitialProfileData] = useState(null);
    const [profileData, setProfileData] = useState({
        fullName: '',
        agencyName: '',
        email: '',
        countryCode:'',
        phone:'',
      });

    useEffect(() => {
        getDetail();
    }, []);

     const getDetail = () => {
        const user = JSON.parse(localStorage.getItem('agent'));
        let url = `get-agent-profile`;
        console.log(url);
        get_ApiManager(user['id'], url, (res) => {
          console.log('my-profile', res);
          if (res.data.data) {
            const fetchedData = {
                fullName: res.data.data['agentname'] || '',
                agencyName: res.data.data['agencyname'] || '',
                email: res.data.data['email'] || '',
                countryCode: res.data.data['country_code'] || '',
                phone: res.data.data['contact'] || '',
            };
            setProfileData(fetchedData);
            setInitialProfileData(fetchedData);
          }
        }, (err) => {
          console.log(err);
        });
      };

      const handleInputChange = (e) => {
        const { name, value } = e.target;
        setProfileData((prevData) => ({
            ...prevData,
            [name]: value
          }));
      
      };

      const handleSubmit = () => {
        setLoading(true)
        if (JSON.stringify(profileData) === JSON.stringify(initialProfileData)) {
            console.log('No changes made');
            swal("Can't update profile", 'No changes made','info')
            return;
        }
        let url = `update-profile`;
        const data = {
            data : profileData,
            id: JSON.parse(localStorage.getItem('agent_id'))
        }
        console.log(data);
        post_ApiManager(data, url, (res) => {
            console.log('profile updated',res);
            setLoading(false)
            if(res.data.error) swal(" ", res.data.error, "error")
            else {
                getDetail();
                swal("Success!", res.data.message, "success"); 
               }  
        },(err) => {
            console.log(err);
        }); 
      }

    return (
        <div className="row px-4 pb-4 pt-2">
        <Title title={'Edit Your Profile'}/>
        <div className='row p-4 border round bg-white j-content-center'>

        <div className='col-lg-5 my-2'>
            <label className="profile-label">Full Name:</label>
            <input
                className="card-text form-control acc-form col-lg-11"
                value={profileData.fullName}
                name="fullName"
                onChange={handleInputChange}
                placeholder={'Enter your full name'}
            />
         </div>

         <div className='col-lg-5 my-2'>
            <label className="profile-label">Agency Name:</label>
            <input
                className="card-text form-control acc-form col-lg-11"
                value={profileData.agencyName}
                name="agencyName"
                onChange={handleInputChange}
                placeholder={"Enter your agency name"}
            />
         </div>

         <div className='col-lg-5 my-2'>
            <label className="profile-label">Email:</label>
            <input
                className="card-text form-control acc-form col-lg-11"
                value={profileData.email}
                name="email"
                onChange={handleInputChange}
                placeholder={"Enter your email"}
            />
        </div>
         
         <div className="col-lg-5 my-2">
         <label className="acc-label2 mb-1">Phone</label>
         <div className="col-12 d-flex pl-0">
            <PhoneInput
            international
            defaultCountry={defaultCountry}
            value={profileData.countryCode}
            name='countryCode'
            className="d-flex col-lg-7 pr-0 pl-0"
            onChange={countryCode => setProfileData((prevData) => ({
            ...prevData,
            ['countryCode']: countryCode
          }))}
            />  
            <input type="number" name="phone" onChange={handleInputChange} value={profileData.phone}
            className="acc-form contact col-5" />
            </div>
        </div>
        {loading ?
        <div className='col-12 text-center my-5'>
        <img src={smallSpinner} width="2" className="pt-2" alt="loading"  style={{width:'2%'}}/>
        </div>:
        <button type="submit" className="btn btn-success mx-5 my-5" onClick={handleSubmit}>Update Agent</button>}

        </div>
        </div>
    )
}
export default Profile;