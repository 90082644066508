import React from "react";
import ReactPaginate from 'react-paginate';

const Paginations = ({ currentPage = 1, onPageChange, lastPage=1, fixedPerPage=null, totalItems, perItem,itemsPerPage}) => {
   
    const handleCallback = (i) => {
        onPageChange(i.selected + 1);
    } 

    return(
        <>
        <div className="col-xl-6">
            <div className="float-left form-group d-inline-flex mb-3 pr-1">
            {fixedPerPage && (fixedPerPage !== 5 || fixedPerPage !== '5') ?  
            <select className="form-select page-form">
            <option value="10" selected>{fixedPerPage}</option>
            </select> :
            fixedPerPage && (fixedPerPage === 5 || fixedPerPage === '5') ?
            <select defaultValue={itemsPerPage} onChange={(event) => onPageChange(currentPage, event.target.value)}  
            className="form-select page-form">
             <option value="5">5</option>
            <option value="10">10</option>
            <option value="25">25</option>
            <option value="100">100</option>
            <option value="200">200</option>
            <option value={totalItems}>All</option>
            </select>
            :<select defaultValue={itemsPerPage} onChange={(event) => onPageChange(currentPage, event.target.value)}  
            className="form-select page-form">
            <option value="10">10</option>
            <option value="25">25</option>
            <option value="100">100</option>
            <option value="200">200</option>
            <option value={totalItems}>All</option>
            </select>}
            </div>
            <div className="size-16 mt-2 perpage font-serif">
            Page {currentPage} Showing {perItem} of {totalItems} entries
            </div>
        </div>
        <div className="col-xl-6">
            <div className="pagination-float-right">
            <ReactPaginate
            breakLabel="..."
            nextLabel="Next"
            onPageChange={handleCallback}
            pageRangeDisplayed={3}
            pageCount={lastPage}
            marginPagesDisplayed={2}
            previousLabel="Prev"
            renderOnZeroPageCount={null}
            containerClassName="pagination float-right"
            subContainerClassName={"pages pagination"}
            pageClassName={"page-item"}
            pageLinkClassName={"page-link"}
            previousClassName={"page-item"}
            previousLinkClassName={"page-link"}
            nextClassName={"page-item"}
            nextLinkClassName={"page-link"}
            breakClassName={"page-item"}
            breakLinkClassName={"page-link"}
            activeClassName={"active"}
            forcePage={currentPage-1}
        />
        </div>
    </div>
    </>
    )
}
export default Paginations